import 'moment/dist/locale/fr';

import { cloneDeep, get, isNil, isObject, set } from 'lodash';
import { getField } from 'vuex-map-fields';
import { hasDifference } from '@/utils/diffTools';

import { GenericPlatformService } from '@/services';
import { devicesConfig as devices } from '@/data/devices';
import blockTypes from '@/data/animationBlockTypes';
import { partners }  from '@/data/partners.js';

import Platform from '@/models/Platform';
import i18n from '@/i18n.js';
import moment from 'moment';

const platformService = new GenericPlatformService('platforms');

const getDefaultState = () => ({
  currentPlatform: null,
  savedPlatform: null,
  cleanData: null,
  childrenPlatformsLoaded: false,
  currentChildrenPlatforms: {},
  savedChildrenPlatforms: {},
  selectedPlatformID: !isNaN(localStorage.getItem('platform'))
    ? JSON.parse(localStorage.getItem('platform'))
    : null,
  allPlatforms: {},
  userPlatforms: !isNil(localStorage.getItem('user-platforms'))
    ? JSON.parse(localStorage.getItem('user-platforms'))
    : null,
  loading: true,
});

const state = getDefaultState();

const actions = {
  getUserPlatforms({ state, commit, dispatch }, { platforms, id }) {
    commit('setLoading', true);
    let promises = [];
    return platformService.getMany(platforms).then(
      (response) => {
        commit('storeUserPlatforms', { platforms: response, id });
      },
      (errors) => {
        const alert = {
          id: 'platform-receive-failed',
          icon: 'close',
          type: 'error',
          message: 'error.generic',
        };
        dispatch('displayAlert', alert, { root: true });
      }
    );
  },
  /**
   * Get current platform data
   * @param {*} force refresh anyway or not
   */
  get({ state, commit }, playload) {
    commit('setLoading', true);
    const device = playload?.device;
    const force = playload?.force;
    if (
      force ||
      (device && !state.currentChildrenPlatforms?.[device]) ||
      (state.currentPlatform === null && state.selectedPlatformID > 0)
    ) {
      const platformId = device
        ? state.allPlatforms[device]
        : state.selectedPlatformID
      return platformService.get(platformId).then(
        (platform) => {
          if (!device) {
            commit('setAllPlatforms', platform);
            commit('storePlatform', platform);
          }
          commit('storeChildrenPlatform', { device, platform });
          i18n.global.locale = localStorage.getItem('locale') ?? platform.bo_locale_default ?? 'fr';
          moment.locale(i18n.global.locale);
          if (
            !(
              platform?.children &&
              typeof platform?.children === 'object' &&
              Object.keys(platform.children).length > 0
            )
          ) {
            commit('setChildrenPlatformsLoaded', true);
          }
          commit('setLoading', false);
          return Promise.resolve(platform);
        },
        (error) => {
          commit('resetPlatform');
          commit('setLoading', false);
          return Promise.reject(error);
        }
      );
    } else {
      commit('setLoading', false);
      return Promise.resolve(state.currentPlatform);
    }
  },

  getChildrenPlatform({ state, commit, dispatch, getters }) {
    commit('setLoading', true);
    let currentPlatformId =
      localStorage.getItem('platform') ??
      import.meta.env.VITE_PLATFORM_ID ??
      -1;

    const devices = getters.devices;
    if (currentPlatformId > 0) {
      return platformService.get(currentPlatformId).then(
        (platform) => {
          commit('storePlatform', platform);
          commit('setLoading', true);
          let childrenPromises = [];
          var childrens =
            typeof state.currentPlatform.children !== 'undefined'
              ? cloneDeep(state.currentPlatform.children)
              : {};
          for (const [key, value] of Object.entries(childrens)) {
            childrenPromises.push(platformService.get(value));
          }
          return Promise.all(childrenPromises).then(
            (childrenPlatforms) => {
              commit('setChildrenPlatform', { devices, childrenPlatforms });
              commit('setChildrenPlatformsLoaded', true);
              return Promise.resolve(childrenPlatforms);
            },
            (error) => {
              const alert = {
                id: 'children-platform-receive-failed',
                icon: 'close',
                type: 'error',
                message: 'error.generic',
              };
              dispatch('displayAlert', alert, { root: true });
              commit('setLoading', false);
              return Promise.reject(error);
            }
          );
        },
        (error) => {
          commit('resetPlatform');
          const alert = {
            id: 'main-platform-receive-failed',
            icon: 'close',
            type: 'error',
            message: 'error.generic',
          };
          dispatch('displayAlert', alert, { root: true });
          commit('setLoading', false);
          return Promise.reject(error);
        }
      );
    } else {
      commit('setLoading', false);
      return Promise.resolve(state.currentPlatform);
    }
  },

  logoutPlatform({ commit }) {
    commit('logoutPlatform');
  },
  resetPlatform({ commit }) {
    commit('resetPlatform');
  },
  resetUserPlatforms({ commit }) {
    commit('resetUserPlatforms');
  },
  updateSelectedPlatform({ dispatch }) {
    // Force update without reseting platform data
    return dispatch('get', true);
  },

  updateAll({ commit, dispatch, getters }) {
    commit('setLoading', true);

    // remove unnecessaray data and set data before saving
    commit('beforeUpdate');
    const devices = getters.devices;
    var promises = [];
    const platformId = cloneDeep(
      state.currentPlatform.id ?? state.cleanData.id
    );
    if (hasDifference(state.currentPlatform, state.savedChildrenPlatforms)) {
      promises.push(platformService.update(platformId, state.currentPlatform));
    }

    devices.forEach(function (device) {
      var deviceType = device.id;
      if (typeof state.currentChildrenPlatforms[deviceType] !== 'undefined') {
        var currentChildren = cloneDeep(
          state.currentChildrenPlatforms[deviceType]
        );
        var savedChildren = cloneDeep(state.savedChildrenPlatforms[deviceType]);
        if (
          currentChildren.id !== platformId &&
          hasDifference(currentChildren, savedChildren)
        ) {
          promises.push(
            platformService.update(currentChildren.id, currentChildren)
          );
        }
      }
    });

    Promise.all(promises).then(
      (platforms) => {
        platforms.forEach(function (platform) {
          if (platform.parent_id !== null) {
            var device = devices.find(
              (x) => x.platform_type_id === platform.platform_type_id
            ).id;
            commit('storeChildrenPlatform', { device, platform });
          } else {
            commit('storePlatform', platform);
          }
        });
        commit('setLoading', false);
        return Promise.resolve(platforms);
      },
      (errors) => {
        const alert = {
          id: 'platform-update-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        commit('setLoading', false);
        return Promise.reject(errors);
      }
    );
  },

  async update({ commit, dispatch }) {
    // Check if there any change
    if (
      !hasDifference(state.currentPlatform, state.savedPlatform) &&
      !hasDifference(state.savedPlatform, state.currentPlatform)
    ) {
      console.log('Cannot update platform: no update');
      return Promise.resolve();
    }

    // remove unnecessaray data and set data before saving
    commit('beforeUpdate');
    dispatch('rentals/update', null, { root: true });

    return platformService
      .update(state.selectedPlatformID ?? state.cleanData.id, state.cleanData)
      .then(
        (platform) => {
          commit('storePlatform', platform);
          commit('setLoading', false);

          return Promise.resolve(platform);
        },
        (errors) => {
          // commit('resetToSaved')
          console.log('platfoms update errors');
          console.log(errors);

          const alert = {
            id: 'platform-update-failed',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });

          return Promise.reject(errors);
        }
      );
  },
  updateChildren({ commit, dispatch }, device) {
    commit('setLoading', true);
    if (
      !hasDifference(
        state.currentChildrenPlatforms[device],
        state.savedChildrenPlatforms[device]
      ) &&
      !device.is_parent_platform
    ) {
      // parent is already updated beside
      return Promise.resolve();
    }
    const childPlatform = state.currentChildrenPlatforms[device];
    return platformService.update(childPlatform.id, childPlatform).then(
      (platform) => {
        commit('storeChildrenPlatform', { device, platform });
        commit('setLoading', false);

        return Promise.resolve(platform);
      },
      (errors) => {
        // commit('resetToSaved')
        console.log('children platfoms update errors');
        console.log(errors);

        const alert = {
          id: 'children-platform-update-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(errors);
      }
    );
  },

  async updateBOMaintenanceMode({ commit, rootState, dispatch }, enable) {
    const data = {
      enable: enable
    }
    const personalToken = rootState.auth.authUser?.tokens?.personal;
    return platformService
      .updateBOMaintenanceMode(data, personalToken)
      .then(
        (response) => {
          commit('setSuccessPlatformMaintenanceMode', enable);
          let alert = {
            id: 'platform-update-success',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(response);
        },
        (errors) => {
          commit('setFailedPlatformMaintenanceMode', enable);
          const alert = {
            id: 'platform-update-failed',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(errors);
        }
      );
  },

  updateField({ commit }, payload) {
    commit('updateField', payload);
  },
  updateFieldInPlatformConfig({ commit }, payload) {
    commit('updateFieldInPlatformConfig', payload);
  },
  updateProgramSearchConfig({ commit }, payload) {
    commit('updateProgramSearchConfig', payload);
  },
};

const mutations = {
  // Remove unnecessaray data before save
  beforeUpdate(state) {
    let cleanData = cloneDeep(state.currentPlatform);

    // Clean formats
    cleanData.content_config.formats = cleanData.content_config.formats.map(
      (element) => {
        delete element.image;
        element.metadatas = element.metadatas.map((metadata) =>
          (({ id, metadata_id, type, textOptions }) => ({
            id,
            metadata_id,
            type,
            textOptions,
          }))(metadata)
        );
        return element;
      }
    );

    // Remove base64 url data
    if (cleanData.picture !== null) {
      for (const [key, value] of Object.entries(cleanData.picture)) {
        delete cleanData.picture[key].url;
      }
    }

    // Set hover colors for primary and secondary
    if (isNil(cleanData.content_config.colors.default['primary-hover']) || cleanData.content_config.colors.default['primary-hover'] === '#') {
      cleanData.setHoverColor('primary');
    }
    if (isNil(cleanData.content_config.colors.default['secondary-hover']) || cleanData.content_config.colors.default['secondary-hover'] === '#') {
      cleanData.setHoverColor('secondary');
    }
    // Set good values for optional and mandatory pictures
    cleanData.setPicturesOptionalMandatory();
    // Remove unused data
    cleanData.removeDeprecated();

    // Set data into cleanData. Used for saving
    state.cleanData = cleanData;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setSelectedPlatformID(state, selectedPlatformID) {
    // Utiliser dans src/helpers/axiosInterceptor
    localStorage.setItem('platform', parseInt(selectedPlatformID));
    state.selectedPlatformID = parseInt(selectedPlatformID);
  },
  setAllPlatforms(state, platform) {
    state.allPlatforms = cloneDeep(platform.children)
    const platformType = platform.type
    state.allPlatforms[platformType] = platform.id;
  },
  storeUserPlatforms(state, { platforms, id = null }) {
    state.userPlatforms = platforms;
    platforms.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    localStorage.setItem('user-platforms', JSON.stringify(platforms));
    let selectedPlatformID = parseInt(platforms[0].id);
    if (id !== null && platforms.find((x) => parseInt(x.id) === parseInt(id))) {
      selectedPlatformID = parseInt(id);
    }
    localStorage.setItem('platform', selectedPlatformID);
    state.selectedPlatformID = selectedPlatformID;
  },
  storePlatform(state, platformData) {
    const platform = new Platform(platformData);
    state.currentPlatform = platform;
    state.savedPlatform = cloneDeep(state.currentPlatform);
    state.cleanData = null;
    localStorage.setItem('fo_locale', platform.fo_locale_default ?? 'en');
  },
  setSuccessPlatformMaintenanceMode(state, value) {
    state.currentPlatform.content_config.bo_maintenance_mode = value;
    state.savedPlatform.content_config.all_bo_maintenance_mode = value
    state.savedPlatform.content_config.bo_maintenance_mode = value
  },
  setFailedPlatformMaintenanceMode(state, value) {
    state.currentPlatform.content_config.all_bo_maintenance_mode = !value;
  },
  storeChildrenPlatform(state, { device, platform }) {
    let _device = device ?? 'desktop';
    state.currentChildrenPlatforms[_device] = new Platform(platform);

    state.savedChildrenPlatforms[_device] = cloneDeep(
      state.currentChildrenPlatforms[_device]
    );
  },
  setChildrenPlatformsLoaded(state, value) {
    state.childrenPlatformsLoaded = value;
  },
  setChildrenPlatform(state, { devices, childrenPlatforms }) {
    const childrenPlatformsClear = {};
    devices.forEach(function (device) {
      var device_id = device.id;
      var device_id_ext = device_id === 'desktop' ? 'main' : device_id;
      if (
        typeof state.currentPlatform.children[device_id_ext] === 'undefined'
      ) {
        //do nothing, admin has to create child store
      } else {
        var platform_id = state.currentPlatform.children[device_id_ext];
        var index = childrenPlatforms.findIndex((x) => x.id === platform_id);
        if (index >= 0) {
          var platform = new Platform(childrenPlatforms[index]);
          if (typeof platform.content_config.animation === 'undefined') {
            platform.content_config.animation = cloneDeep(
              state.currentPlatform.content_config.animation
            );
          }
          childrenPlatformsClear[device_id] = platform;
        }
      }
    });
    childrenPlatformsClear[state.currentPlatform.platformType] = {
      id: state.currentPlatform.id,
      is_parent_platform: true,
      platformType: state.currentPlatform.platformType,
      hint: 'do not use, this element is only used for listing',
    };
    state.currentChildrenPlatforms = cloneDeep(childrenPlatformsClear);
    state.savedChildrenPlatforms = cloneDeep(childrenPlatformsClear);
    state.loading = false;
  },
  resetPlatform(state) {
    state.currentPlatform = null;
    state.savedPlatform = null;
    state.currentChildrenPlatforms = {};
    state.savedChildrenPlatforms = {};
  },
  logoutPlatform(state) {
    Object.assign(state, getDefaultState());
  },
  resetToSaved(state) {
    state.currentPlatform = cloneDeep(state.savedPlatform);
    state.setLoading = false;
  },
  resetUserPlatforms(state) {
    state.userPlatforms = null;
  },

  switchOauth(state, service) {
    var status = cloneDeep(state.currentPlatform.user_config[service].enabled);
    state.currentPlatform.user_config[service].enabled = !status;
  },
  switchRecommendations(state, service) {
    var status = cloneDeep(
      get(state, `currentPlatform.content_config[${service}].enabled`, false)
    );
    set(state, `currentPlatform.content_config[${service}].enabled`, !status);
  },
  setOauth(state, { service, field, value }) {
    state.currentPlatform.user_config[service][field] = value;
    if (
      state.currentPlatform.user_config[service].client_id === null ||
      state.currentPlatform.user_config[service].client_id.length === 0 ||
      state.currentPlatform.user_config[service].client_secret === null ||
      state.currentPlatform.user_config[service].client_secret.length === 0
    ) {
      state.currentPlatform.user_config[service].enabled = false;
    }
  },
  switchCaptcha(state, { service, page }) {
    var status = cloneDeep(
      state.currentPlatform.user_config.captcha[service].enabled[page]
    );
    state.currentPlatform.user_config.captcha[service].enabled[page] = !status;
  },
  setCaptcha(state, { service, field, value }) {
    let captcha = cloneDeep(state.currentPlatform.user_config.captcha[service]);

    if (field === 'min_score') {
      let old = captcha.min_score;
      if (isNaN(value)) {
        state.currentPlatform.user_config.captcha[service].min_score = old;
      } else if (Number(value) < 0) {
        state.currentPlatform.user_config.captcha[service].min_score = 0;
      } else if (Number(value) > 1) {
        state.currentPlatform.user_config.captcha[service].min_score = 1;
      } else {
        state.currentPlatform.user_config.captcha[service].min_score =
          Number(value);
      }
    } else {
      state.currentPlatform.user_config.captcha[service][field] = value;
    }

    if (
      captcha.client_id === null ||
      captcha.client_id.length === 0 ||
      captcha.client_secret === null ||
      captcha.client_secret.length === 0 ||
      isNaN(captcha.min_score) ||
      captcha.min_score > 1 ||
      captcha.min_score < 0
    ) {
      let pages = Object.entries(
        state.currentPlatform.user_config.captcha[service].enabled
      );
      for (const [key, value] of pages) {
        state.currentPlatform.user_config.captcha[service].enabled[key] = false;
      }
    }
  },
  setRecommendations(state, { service, field, value }) {
    const config =
      cloneDeep(state.currentPlatform.content_config[service]) || {};

    if (config.api_key === null || config.base_uri === null) {
      state.currentPlatform.content_config[service].enabled = false;
      set(state, `currentPlatform.content_config[${service}].enabled`, false);
    }
    set(state, `currentPlatform.content_config[${service}].${field}`, value);
  },
  switchUserField(state, field) {
    var status = cloneDeep(
      state.currentPlatform.user_config.otto.user_info[field]
    );
    state.currentPlatform.user_config.otto.user_info[field] = !status;
  },
  switchContentAuthorization(state) {
    var status = cloneDeep(
      state.currentPlatform.user_config.otto.content_authorization.enabled
    );
    state.currentPlatform.user_config.otto.content_authorization.enabled =
      !status;
    if (!state.currentPlatform.user_config.otto.content_authorization.enabled) {
      state.currentPlatform.user_config.otto.content_authorization.metadata_detail_id =
        null;
    }
  },
  updateContentAuthorizationMetadataDetailId(state, value) {
    state.currentPlatform.user_config.otto.content_authorization.metadata_detail_id =
      value.id;
  },
  updateContentAuthorizationField(state, { field, value }) {
    state.currentPlatform.user_config.otto.content_authorization[field] = value;
  },

  addSeo(state, page) {
    var pages = cloneDeep(state.currentPlatform.content_config.seo.pages);
    pages[page.id] = { title: null, description: null };
    state.currentPlatform.content_config.seo.pages = pages;
  },
  removeSeo(state, { page }) {
    var temp = cloneDeep(state.currentPlatform.content_config.seo.pages);
    delete temp[page];
    state.currentPlatform.content_config.seo.pages = temp;
  },
  setSeo(state, { page, field, value }) {
    state.currentPlatform.content_config.seo.pages[page][field] = value;
  },

  updateField(state, payload) {
    let { path, subpath, value, pathLevel2 = null } = payload;
    if (isNil(state.currentPlatform.content_config[path])) {
      console.log(
        'cannot update field ' + path + '/' + subpath + ' with value ' + value
      );
      return;
    }

    if (pathLevel2 !== null && subpath !== null) {
      state.currentPlatform.content_config[path][subpath][pathLevel2] = value;
    } else if (
      subpath === null &&
      !isObject(state.currentPlatform.content_config[path])
    ) {
      state.currentPlatform.content_config[path] = value;
    } else {
      state.currentPlatform.content_config[path][subpath] = value;
    }
  },
  updateFieldInPlatformConfig(state, payload) {
    let { config, path, subpath, value, pathLevel2 = null } = payload;
    if (config === null && path !== null) {
      state.currentPlatform[path] = value;
      return;
    }
    if (isNil(state.currentPlatform[config])) {
      console.log(
        'cannot update field in ' +
        config +
        ' config' +
        path +
        '/' +
        subpath +
        ' with value ' +
        value
      );
      return;
    }
    if (pathLevel2 !== null && subpath !== null) {
      state.currentPlatform[config][path][subpath][pathLevel2] = value;
    } else if (
      subpath === null &&
      !isObject(state.currentPlatform[config][path])
    ) {
      state.currentPlatform[config][path] = value;
    } else {
      state.currentPlatform[config][path][subpath] = value;
    }
  },
  /**
   * @since Move from site.module.js
   * @author Jody JUSTINE <jjustine@vodfacoy.com>
   **/
  updateSiteParametersFormat: (state, format) => {
    state.currentPlatform.content_config.formats =
      state.currentPlatform.content_config.formats.map((x) => {
        x.checked = false;
        return x.id === format.id ? format : x;
      });

    var id = format.id;
    var currentFormat = state.currentPlatform.content_config.catalog.template;
    var newTemplate =
      currentFormat.substring(0, currentFormat.indexOf('-')) +
      '-' +
      id.substring(0, id.length - 1);
    state.currentPlatform.content_config.catalog.template = newTemplate;
    for (const [device, value] of Object.entries(
      state.currentChildrenPlatforms
    )) {
      if (!state.currentChildrenPlatforms[device]?.is_parent_platform) {
        state.currentChildrenPlatforms[device].content_config.catalog.template =
          newTemplate;
      }
    }
  },
  updateRowAnimation(state, { device, block, value }) {
    let newValue = cloneDeep(value);
    if (state.currentChildrenPlatforms[device]?.is_parent_platform) {
      newValue.differsParent = false;
      state.currentPlatform.content_config.animation[block] = newValue;
      devices.forEach(function (device) {
        var deviceId = device.id;
        if (
          typeof state.currentChildrenPlatforms[deviceId] !== 'undefined' &&
          !state.currentChildrenPlatforms[deviceId]?.is_parent_platform &&
          !state.currentChildrenPlatforms[deviceId]?.content_config?.animation[
            block
          ]?.differsParent
        ) {
          state.currentChildrenPlatforms[deviceId].content_config.animation[
            block
          ] = newValue;
        }
      });
    } else {
      newValue.differsParent = true;
      state.currentChildrenPlatforms[device].content_config.animation[block] =
        newValue;
    }
  },
  updateAvodVideoConfig(state, { device, videoType, field, value }) {
    if (state.currentChildrenPlatforms[device].is_parent_platform) {
      state.currentPlatform.content_config.avod.ads.videos[videoType][field] =
        value;
    } else {
      state.currentChildrenPlatforms[device].content_config.avod.ads.videos[
        videoType
      ][field] = value;
    }
  },
  updateAvodPageBlockConfig(state, { device, page, blockType, field, value }) {
    if (state.currentChildrenPlatforms[device].is_parent_platform) {
      state.currentPlatform.content_config.avod.ads.pages[page].formats[
        blockType
      ][field] = value;
    } else {
      state.currentChildrenPlatforms[device].content_config.avod.ads.pages[
        page
      ].formats[blockType][field] = value;
    }
  },

  updateAdTypeConfig(state, { device, adType, field }) {
    const newField = cloneDeep(field);
    if (state.currentChildrenPlatforms[device].is_parent_platform) {
      state.currentPlatform.content_config.avod[adType][newField.id] =
        newField.value;
      devices.forEach(function (device) {
        const deviceId = device.id;
        if (
          typeof state.currentChildrenPlatforms[deviceId] !== 'undefined' &&
          !state.currentChildrenPlatforms[deviceId].is_parent_platform &&
          !state.currentChildrenPlatforms[deviceId].content_config.avod[adType]
            .differsParent
        ) {
          state.currentChildrenPlatforms[deviceId].content_config.avod[adType][
            newField.id
          ] = newField.value;
          state.currentChildrenPlatforms[deviceId].content_config.avod[
            adType
          ].differsParent = false;
        }
      });
    } else {
      state.currentChildrenPlatforms[device].content_config.avod[adType][
        newField.id
      ] = newField.value;
      state.currentChildrenPlatforms[device].content_config.avod[
        adType
      ].differsParent = true;
    }
  },
  setCleanData(state, cleanData) {
    state.cleanData = cleanData;
  },
  updateLayoutCustom(state, { layout, value }) {
    state.currentPlatform.setLayoutCustomTextColors({ layout, value });
  },
  updateLayout(state, { layout, name, value }) {
    state.currentPlatform.setLayoutColors({ layout, name, value });
  },
  updateCustomBackgroundDefaultColor(state, payload) {
    state.currentPlatform.content_config.colors.layout.default.background.hex =
      payload;
  },
  updateCustomBackgroundSubscribeColor(state, payload) {
    state.currentPlatform.content_config.colors.layout.subscribe.background.hex =
      payload;
  },
  removeSearchWeight(state, { engine, id }) {
    var temp = cloneDeep(state.currentPlatform.search_config[engine].fields);
    delete temp[id];
    state.currentPlatform.search_config[engine].fields = temp;
  },
  updateSearchWeight(state, { engine, id, weight }) {
    var temp = cloneDeep(state.currentPlatform.search_config[engine].fields);
    temp[id] = weight;
    state.currentPlatform.search_config[engine].fields = temp;
  },

  removeRecommendationsIndex(state, { engine, index }) {
    const currentIndexes = cloneDeep(
      get(
        state,
        `currentPlatform.content_config.rumo.source[${engine}].indexes`,
        []
      )
    );

    const newIndexes = currentIndexes.filter((t) => {
      // Old format
      if (typeof t === 'string') {
        return t !== index.name;
      }

      // New format with filter (sub metadata)
      return t.rumoName !== index.rumoName;
    });

    set(
      state,
      `currentPlatform.content_config.rumo.source[${engine}].indexes`,
      newIndexes
    );
  },
  updateRecommendationsIndex(state, { engine, index }) {
    const currentIndexes = cloneDeep(
      get(
        state,
        `currentPlatform.content_config.rumo.source[${engine}].indexes`,
        []
      )
    );

    const updateIndex = currentIndexes.findIndex((t) => {
      // Old format
      if (typeof t === 'string') {
        return t === index.name;
      }

      // New format with filter (sub metadata)
      return t.rumoName === index.rumoName;
    });

    if (updateIndex === -1) {
      currentIndexes.push(index);
    } else {
      currentIndexes[updateIndex] = index;
    }

    set(
      state,
      `currentPlatform.content_config.rumo.source[${engine}].indexes`,
      currentIndexes
    );
  },
  updatePagination(state, { title, value }) {
    const temp = cloneDeep(state.currentPlatform.content_config?.pagination);
    temp[title] = value;
    state.currentPlatform.content_config.pagination = temp;
  },

  updateProgramSearchConfig(state, { field, value }) {
    state.currentPlatform.search_config.program[field] = value;
  }
};

const getters = {
  getField,
  hasPlatforms: (state) => !!state.userPlatforms,
  hasChildrenPlatforms: (state) =>
    state.currentPlatform?.children
      ? Object.keys(state.currentPlatform.children).length > 0
      : false,
  currentPlatform: (state) => state.currentPlatform,
  childrenPlatforms: (state) =>
    Object.keys(state.currentPlatform?.children || {}),
  mainDevice: (state) => state.currentPlatform?.platformType,
  devices: (state, getters) => {
    if (!state.currentPlatform) {
      return [];
    }
    return devices.filter(
      (x) => state.allPlatforms?.[x.id]
    );
  },
  getCurrentPlatformName: (state) =>
    state.currentPlatform ? state.currentPlatform.name : '--',
  getPlatformNameToReset: (state) =>
    state.userPlatforms && state.userPlatforms[0]
      ? state.userPlatforms[0].name
      : '--',
  shouldSaved: (state) =>
    hasDifference(state.currentPlatform, state.savedPlatform) ||
    hasDifference(state.currentChildrenPlatforms, state.savedChildrenPlatforms),
  shouldSaveMain: (state) =>
    hasDifference(state.currentPlatform, state.savedPlatform),
  loading: (state) => state.loading,
  currentFormat: (state) =>
    state.currentPlatform?.content_config?.formats?.find((x) => x.checked) ??
    null,
  currentPlatformId: (state) => (mode) => {
    if (
      'parent' === mode ||
      (state.currentPlatform !== null &&
        state.currentPlatform.platformType === mode)
    ) {
      return state.currentPlatform.id;
    } else {
      return state.savedChildrenPlatforms[mode]?.id ?? null;
    }
  },

  getPlatformIdByDeviceName: (state) => (device) => {
    return state.allPlatforms?.[device] ?? null;
  },

  getPlatformTypeByPlatformId: (state) => (platformId) => {
    if (state.allPlatforms && platformId) {
      for (const [device, value] of Object.entries(state.allPlatforms)) {
        if (value === platformId) {
          return device;
        }
      }
    }
    return null;
  },
  getPlatformByDeviceName: (state, getters, rootState) => (device) => {
    return rootState.platforms?.currentChildrenPlatforms?.[device]
      ?? null;
  },
  featureEnabled: (state) => (feature) => 
    state.currentPlatform.content_config.feature?.[feature],

  hasFreeboxPlatform: (state) => {
    const FREE_PLATFORM_NAME = 'free';
    return (
      (state.currentPlatform ?? false) &&
      (state.currentPlatform.platformType === FREE_PLATFORM_NAME ||
        Object.keys(state.currentPlatform.children).includes(
          FREE_PLATFORM_NAME
        )) &&
      state.currentPlatform.content_config?.feature?.freebox_videos
    );
  },
  getBlockTypes: (state) => {
    return blockTypes.filter((type) => {
      if (type?.canEnable) {
        return state.currentPlatform?.content_config?.feature['block_' + type.id.replace('-', '')]
          ?? false;
      }
      return true;
    });
  },
  defaultColors: (state) =>
    state.currentPlatform?.content_config?.colors?.default,
  colorHex: (state, getters) => (configColor) => {
    let type = configColor
    if (type && type.startsWith('#')) {
      return type;
    } else if (type === 'custom') {
      const content_config = state.currentPlatform?.content_config
      if (
        content_config?.feature?.custom_background_color &&
        content_config.colors.layout?.default?.background?.id === 'custom'
      ) {
        return content_config.colors.layout.default.background.hex;
      } else {
        type = content_config.colors.layout?.['layouts.default'] === 'dark'
          ? 'black'
          : 'white';
      }
    }
    if (type === 'white') {
      return '#FFFFFF';
    } else if (type === 'black') {
      return '#000000';
    } else if (getters.defaultColors) {
      return getters.defaultColors[type];
    }
  },
  features: (state) => state.currentPlatform?.content_config?.feature ?? {},
  pictureTypesMandatory: (state) =>
    state.currentPlatform?.content_config?.picture?.mandatory,
  pictureTypesOptional: (state) =>
    state.currentPlatform?.content_config?.picture?.optional,
  pictureTypesPartner: (state) =>
    state.currentPlatform?.content_config?.picture?.partner,
  pictureTypesSelectionMandatory: (state) =>
    state.currentPlatform?.content_config?.picture?.selection?.mandatory,
  pictureTypesSelectionOptional: (state) =>
    state.currentPlatform?.content_config?.picture?.selection?.optional,
  rentalsAreAllFilled:
    (state) =>
      (type = null) => {
        let rentals = type
          ? state.currentPlatform.content_config.rentals.filter(
            (r) => r.type === type
          )
          : state.currentPlatform.content_config.rentals;
        for (let i = 0; i < rentals.length; i++) {
          const rental = rentals[i];
          const rentalIsValid =
            rental !== null &&
            rental.price_amount !== null &&
            rental.count !== null &&
            rental.interval !== null &&
            rental.quality !== null;

          if (!rentalIsValid) {
            return false;
          }
        }
        return true;
      },


  /**
   * Checks if the current platform's pricing type is free.
   *
   * @returns {boolean}
   */
  platformPricingTypeIsFree: (state) => {
    return (
      ['free_with_registration', 'free_without_registration'].indexOf(
        state.currentPlatform.content_config.pricing_type
      ) > -1
    );
  },

  /**
   * Checks if the current platform is available on OTT (Over-The-Top) devices (e.g. Web, Android, iOS, etc.).
   *
   * @returns {boolean}
   */
  platformIsAvailableOnOtt: (state) => {
    const ottDevices = devices.filter((device) => device.isOtt)
      .map((device) => device.id);
    return ottDevices.some(key => state.currentPlatform.family.hasOwnProperty.bind(key));
  },

  getActivePartnerEnabled: (state) => {
    return partners.filter((partner) => 
      state.currentPlatform?.content_config?.feature[partner.featureName] ?? false);
  },
  hasAtleastOnePartnerEnabled: (state) => {
    return partners.some((partner) => 
      state.currentPlatform?.content_config?.feature[partner.featureName]
    );
  }
};

export const platforms = {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
