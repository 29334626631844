import { Buffer } from 'buffer';
import { cloneDeep } from 'lodash';
import S3Uploader from '@/services/aws/s3uploader.service';

const state = {
  loading: false,
  s3uploader: null,
};

const actions = {
  uploadFile({ commit, dispatch, rootState, state }, file) {
    commit('setLoading', true);
    commit('initS3Uploader');
    let s3uploader = cloneDeep(state.s3uploader);
    let mimeType = file.url
      .substring(0, file.url.indexOf(';base64,'))
      .replace('data:', '');
    let extension = mimeType.split('/')[1];
    let encodedFile = file.url.replace('data:' + mimeType + ';base64,', '');
    let decodedFile = Buffer.from(encodedFile, 'base64');

    return s3uploader
      .uploadFile({
        file: decodedFile,
        mimeType: mimeType,
        platformId: rootState.platforms.currentPlatform.id,
        extension: extension,
      })
      .then(
        (upload) => {
          const alert = {
            id: 'upload-success',
            icon: 'check',
            type: 'valid',
            message: file.alerts
              ? file.alerts.success
              : 'notifications.s3.success',
          };
          dispatch('displayAlert', alert, { root: true });
          commit('setLoading', false);
          return Promise.resolve(upload);
        },
        (error) => {
          const alert = {
            id: 'upload-failed',
            icon: 'close',
            type: 'error',
            message: file.alerts ? file.alerts.error : 'notifications.s3.error',
          };
          commit('setLoading', false);
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  removeUpload({ commit }, key) {
    commit('removeUpload', key);
  },
};

const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },
  initS3Uploader(state) {
    if (state.s3uploader === null) {
      state.s3uploader = new S3Uploader();
    }
  },
};

export const s3Uploader = {
  namespaced: true,
  state,
  actions,
  mutations,
};
