import utils from '@/utils/utils';

export class ItemTransformer {
  _item;
  _references;

  constructor(item, references) {
    this._item = item;
    this._references = references;
  }

  transform(rank = null) {
    if (!this._item?.item_type_id) {

      //FORCE item type to text
      if (this._item.type === 'custom') {
        this._item.type = 'text';
      }
      const currentItemReference = this._references.all.items.find(
        (itemReference) => itemReference.name === this._item.type
      );

      if (currentItemReference) {
        this._item.item_type_id = currentItemReference.id;
      }
    }
    if (['program', 'serie', 'season', 'episode', 'collection'].includes(this._item.type)) {
      this._item.itemable_type = 'program';
      this._item.itemable_id = this._item?.program?.id;
    } else if (this._item.type === 'selection') {
      this._item.itemable_type = 'selection';
      this._item.itemable_id = this._item?.selection?.id;
    } else if (this._item.type === 'plan') {
      this._item.itemable_type = 'plan';
      this._item.itemable_id = this._item?.plan?.id;
    } else if (this._item.type === 'metadata') {
      this._item.itemable_type = this._item?.metadata?.type;
      this._item.itemable_id = this._item?.metadata?.id;
    }

    delete this._item.program;
    delete this._item.selection;
    delete this._item.plan;
    delete this._item.metadata;

    if (this._item?.subtitles) {
      let subtitles = [];
      this._item.subtitles.forEach((subtitle) => {
        if (subtitle?.value && subtitle?.value !== "") {
          subtitles.push(subtitle);
        }
      });
      this._item.configuration.subtitles = subtitles;
      delete this._item.subtitles;
    }

    if (this._item?.show_cta_button) {
      this._item.configuration.show_cta_button = this._item.show_cta_button;
      delete this._item.show_cta_button;
    }

    if (this._item?.video?.id) {
      this._item.configuration.video = {
        id: this._item.video.id,
        name: this._item.video.name,
      };
    }
    delete this._item.video;

    if (typeof this._item?.picture === 'object') {
      for (const [key, value] of Object.entries(this._item.picture)) {
        if (value.length > 0 && value[0].id === null && value[0].key) {
          this._item.picture[key][0].url = null;
        }
      }
    }

    [
      { fieldName: 'cta_label', maxlength: 250 },
      { fieldName: 'cta_url', maxlength: 500 },
      { fieldName: 'description', maxlength: 5000 },
      { fieldName: 'title', maxlength: 250 }
    ].forEach((fieldDefinition) => {
      if (typeof this._item[fieldDefinition.fieldName] !== 'string') {
        delete this._item[fieldDefinition.fieldName];
      } else if (this._item[fieldDefinition.fieldName].length === 0) {
        this._item[fieldDefinition.fieldName] = '';
      } else if (
        this._item[fieldDefinition.fieldName] &&
        this._item[fieldDefinition.fieldName].length > fieldDefinition.maxlength
      ) {
        this._item[fieldDefinition.fieldName] = utils.shorten(
          this._item[fieldDefinition.fieldName],
          fieldDefinition.maxlength
        );
      }
    });
    if (rank !== null) {
      this._item.rank = rank;
    }
    if (this._item?.description === undefined) {
      this._item.description = null;
    }
    /*
    if ('newItemID' in this._item) {
      delete this._item.newItemID;
    }
     */
    if ('newItem' in this._item) {
      delete this._item.newItem;
    }

    return this._item;
  }
}
