<template>
  <div class="flex flex-col">
    {{ rawSubtitles }}
    <BaseButton
      link
      :with-theme="false"
      size="xxs"
      font-weight="normal"
      text-align="left"
      @click="$emit('toggle')"
    >
      <Icon icon="subtitle" size="md" />
      <span class="text-xs line-clamp-1 break-all">{{
        $tc('videos.subtitles.count', subtitles.length)
      }}</span>
      <Icon
        icon="caret-down"
        class="transform transition-transform ease-in-out"
        size="xs"
        :class="{ '-rotate-180': show }"
      />
    </BaseButton>

    <ul v-if="show">
      <li class="first:mt-8 mt-4">
        <BaseButton
          link
          :with-theme="false"
          font-weight="normal"
          size="xxs"
          icon-before="add"
          @click="showModalAddSubtitle()"
          :disabled="!candEditSubtitle"
        >
          <Icon icon="add" size="sm" />
          <span class="text-xs font-weight-medium">{{ $t('videos.subtitles.button.add') }}</span>
        </BaseButton>
      </li>
      <li
        v-for="(subtitle, i) in subtitles"
        :key="i"
        class="otto-base-ui-component size-xs flex items-center mt-2 theme-gray !pl-6"
      >
        <Tag size="xs" type="filled" weight="normal" tabindex="-1" :disabled="!candEditSubtitle">
          <span class="inline-flex break-all line-clamp-1 text-xs">{{
            subtitle.display
          }}</span>
          <div
            class="cursor-pointer"
            @click="removeThisSubtitle(subtitle)"
            :disabled="!candEditSubtitle"
          >
            <Icon icon="close" size="xs" />
          </div>
        </Tag>
      </li>
    </ul>
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import BaseButton from '@/components/button/BaseButton.vue';
import Icon from '@/components/icon/Icon.vue';
import Tag from '@/components/tag/Tag.vue';
import subtitleTypes from '@/data/subtitleTypes.js';

export default {
  name: 'VideoSubtitles',
  components: {
    BaseButton,
    Icon,
    Tag,
  },
  props: {
    videoData: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
    },
    partnerId: {
      type: String,
      default: null
    },
  },
  computed: {
    candEditSubtitle() {
      return this.partnerId !== 'betv' &&
       (this.partnerId === 'otto' || this.type === 'program');
    },
    subtitles() {
      const rawSubtitles = this.videoData?.subtitles ?? {};
      const subtitles = [];
      const partnerWithSubtype = ['molotov', 'amazon'].includes(this.partnerId);
      if (Object.keys(rawSubtitles).length === 0) {
        return subtitles;
      }
      for (const [formatKey, formatValue] of Object.entries(rawSubtitles)) {
        for (const [languageKey, languageValue] of Object.entries(
          formatValue
        )) {
          const langSubtitle = formatValue[languageKey];
          const lang = this.getLanguageName(langSubtitle.locale);
          const type = this.$t('videos.subtitles.types.' + (langSubtitle.type ?? 'normal'));

          const display = `${lang} - ${langSubtitle.format}` + (partnerWithSubtype ? ` (${type})` : '');
          const subtitle = {
            id: langSubtitle.id,
            format: langSubtitle.format,
            locale: langSubtitle.locale,
            lang,
            display,
          };
          subtitles.push(subtitle);
        }
      }
      return subtitles;
    },
    ...mapGetters({
      allLanguages: 'languages/groupedAllLanguages',
    }),
  },
  methods: {
    getLanguageName(iso) {
      const temp = this.allLanguages.filter(
        (x) => x.iso_639_1 === iso || x.iso_639_2 === iso || x.iso_639_3 === iso
      );
      if (temp.length > 0) {
        return temp[0].title;
      } else {
        return iso;
      }
    },

    removeThisSubtitle(subtitle) {
      if (this.type === 'video') {
        this.removeCurrentNewVideoSubtitle(subtitle);
      } else {
        const videoData = cloneDeep(this.videoData);
        this.removeCurrentNewMovieSubtitle({
          label: isEmpty(this.partnerId) ? 'video' : `${this.partnerId}_video`,
          videoId: videoData.id,
          videoType: videoData.type,
          format: subtitle.format,
          locale: subtitle.locale,
        });
      }
    },
    showModalAddSubtitle() {
      const videoData = cloneDeep(this.videoData);

      let usedLocales = {};

      Object.entries(videoData.subtitles).forEach(([formatKey, formatValue]) => {
        usedLocales[formatKey] = [];
        Object.entries(formatValue).forEach(([languageKey, languageValue]) => {
          usedLocales[formatKey].push(languageKey)
        });
      });

      const modalParams = {
        videoData: videoData,
        assetType: 'subtitle',
        type: this.type,
        programId: videoData.program_id,
        videoId: videoData.id,
        videoType: videoData.type,
        partnerId: this.partnerId,
        usedLocales: usedLocales,
      };
  
      const modalName = isEmpty(this.partnerId) || this.partnerId === 'otto' ? 'ModalSubtitlesUpload' : 'ModalAddVideoAssetFromFtp';

      this.showModal({
        componentName: modalName,
        componentParams: modalParams,
        size: 'md',
      });
    },
    ...mapActions('videos', {
      removeCurrentNewVideoSubtitle: 'removeSubtitle',
    }),
    ...mapMutations('programs', {
      removeCurrentNewMovieSubtitle: 'removeMovieSubtitle'
    }),
    ...mapMutations(['showModal']),
  },
};
</script>
