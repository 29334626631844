<template>
  <Modal theme="primary" class="modal-md" size="md" :close="cancelIngest">
    <template #header>
      <h2 class="modal-title" v-html="modalTitle"/>
    </template>
    <template #content>
      <!-- Step 0 : Displays dashboard of ingests and proposed to add more -->
      <div v-if="showProcessingIngests">
        <div class="modal-dashboard">   
          <Card accordion wrapper :initial-item-key="previousIngestsSectionsDefault" multiple>
            <CardSection
              v-for="(dashboardSection, i) in runningngestsSections" :key="i"
              spacer="sm"
              :item-key="dashboardSection.itemKey"
              :separator="false"
            >
              <template #title>
                <h3 class="flex uppercase">
                  {{  dashboardSection.title }}
                  <span
                    v-if="dashboardSection.spinner"
                    class="pl-4 flex italic lowercase font-light text-xs"
                  >
                    <Icon icon="spinner" class="animate-spin" />
                  </span>
                </h3>
              </template>
              <div class="flex flex-col gap-4">
                <Card type="dashed" class="uploading-video" spacer="md">
                  <div class="flex flex-col gap-4">
                    <IngestingVideo
                      v-for="(ingest, j) in getPreviousIngestByStatus(dashboardSection.listName)"
                      :key="j"
                      :ingest="ingest"
                      :ingest-index="j + 1"
                      :total-ingests="ingest.length"
                      :removable="dashboardSection.removable"
                      @archive="archiveIngest(ingest)"
                    />
                  </div>
                </Card>
              </div>
            </CardSection>
            <div class="modal-videos-upload-files-footer pt-4">
              <h4 class="modal-videos-upload-files-footer-subtitle">
                {{ $t('videos.modal.processing.encoding.footer') }}
              </h4>
            </div>
          </Card>
        </div>
        <div class="modal-picto">
          <PictoButton
            icon="video-flat"
            action-icon="upload-filled"
            action-color="info"
            @click="addPendingPath"
          >
            {{ $t('videos.modal.import.button') }}
          </PictoButton>
        </div>
      </div>
      <!-- Step 1 : Displays form into which one admin will input the file path -->
      <div v-if="showFormAddPath" class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <FormControl
            v-for="(pendingPath, i) in pendingPaths"
            :key="i"
            :label="
              i === 0 ? $t('videos.modal.import.server.input') : ''
            "
            :error="pendingPath.path && ! pendingPath.validating && ! pendingPath.valid ? $t('videos.modal.import.server.missing') : ''"
            :valid="pendingPath.valid"
            class="first:mt-0 mt-4"
            :label-tooltip="{
              message: 'videos.modal.import.server.tooltip',
            }"
          >
            <FormInput
              v-model="pendingPath.path"
              autofocus
              :loading="pendingPath.validating"
              :valid="pendingPath.valid"
              @keyup="updateFilePath(i, $event)"
              @update:model-value="updateFilePath(i, $event)"
            />
          </FormControl>
          <BaseButton
            link
            :with-theme="false"
            icon-before="add"
            font-weight="normal"
            :label="$t('videos.video.button.add')"
            @click="addPendingPath"
          />
        </div>
      </div>
      <!-- Step 2 : Displays form to choose encoding environments -->
      <div v-if="showFormSetIngest">
        <h3 class="uppercase mb-3">
          {{ $t('generic.video') }} {{ indexPendingIngest + 1 }} / {{ pendingIngests.length }}
        </h3>
        <div class="grid grid-cols-12 gap-4">
          <FormControl
            :label="$t('videos.modal.import.options.title')"
            class="col-span-12"
          >
            <FormInput v-model="pendingIngest.name" autofocus disabled />
          </FormControl>
          <FormControl
            :label="$t('videos.modal.import.options.type')"
            class="col-span-12 flex"
          >
            <FormRadios
              v-model="pendingIngest.type"
              :field="{
                options: videosTypes,
                checkedOption: null,
              }"
              icon-position="left"
              @update:model-value="updateEnvironmentByType($event)"
            />
          </FormControl>
          <FormControl
            v-if="activePartners?.length > 1"
            :label="$t('videos.modal.import.options.platforms.title')"
            class="col-span-12"
          >
            <div class="flex">
              <FormRadios
                :model-value="pendingIngest.mode"
                :field="{
                  options: ingestEnvironmentGroup,
                  checkedOption: pendingIngest.mode
                }"
                block-label
                @update:model-value="updateEnvironmentByMode($event)"
              />
              <FormSelect
                class="ml-4 w-40"
                :options="activePartners"
                :disabled="pendingIngest.mode !== 'select'"
              >
                <template #title>{{ environmentSelected }}</template>
                <template #option="option">
                  <FormCheckbox
                    :label="$t(option.title)"
                    :model-value="pendingIngest.environments.includes(option.id)"
                    size="sm"
                    @update:model-value="updateEnvironment(option.id, $event)"
                  />
                </template>
              </FormSelect>
            </div>
          </FormControl>
        </div>
      </div>
      <!-- Step 3 : Displays form to set video audio -->
      <div v-if="showFormSetIngestAudio">
        <h3 class="text-center font-light mb-4">
          <Icon
            v-if="ingestEnvironmentProcessing?.environment?.icon"
            :icon="ingestEnvironmentProcessing.environment.icon"
            size="lg"
            class="mr-1"
          />

          {{ ingestEnvironmentProcessing.input_file }}
          <Icon v-if="ingestEnvironmentProcessing.processable" icon="check" color-hex="#6CC87A" class="ml-1"/>
          <Icon v-else icon="alert" class="ml-1"/>
        </h3>
        <div
          v-if="ingestEnvironmentProcessing.group_environment === 'amazon' && ingestEnvironmentProcessing.audioCount > 1"
          class="text-red-500 text-sm font-light text-left"
        >
          {{ $t('videos.modal.processing.audiosubs.error.amazon_too_many') }}
        </div>
        <div
          v-if="(ingestEnvironmentProcessing.group_environment === 'amazon' && ingestEnvironmentProcessing.audioCount === 1) || ingestEnvironmentProcessing.group_environment !== 'amazon'"
        >
          <h4 class="text-center font-light mb-4">
            {{ $t('videos.modal.processing.audiosubs.title') }}
          </h4>
          <Card type="dashed">
            <table class="w-full border-spacing-2 border-separate">
              <thead>
                <tr class="divide-x divide-skin-ui divide-solid">
                  <th class="text-center">
                    <span class="inline-flex items-center gap-2">
                      <Icon icon="audio" />
                      <span>{{
                        $t('videos.modal.processing.audiosubs.track')
                      }}</span>
                    </span>
                  </th>
                  <th class="text-center">
                    <span class="inline-flex items-center gap-2">
                      <Icon icon="subtitle" />
                      <span>{{
                        $t('videos.modal.processing.audiosubs.tagging')
                      }}</span>
                    </span>
                  </th>
                  <th class="text-center">
                    <span class="inline-flex items-center gap-2">
                      <Icon icon="actualize" />
                      <span>{{
                        $t('videos.modal.processing.audiosubs.matching')
                      }}</span>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(iso_code, i) in (ingestEnvironmentProcessing?.mediainfo?.audio ?? [])"
                  :key="i"
                  class="divide-x divide-skin-ui divide-solid"
                >
                  <td class="text-center">
                    {{ i }}
                  </td>
                  <td class="text-center">
                    {{ ingestEnvironmentProcessing.refAudios[i] }}
                  </td>
                  <td>
                    <FormControl
                      :key="i"
                      class="ml-4"
                      :error="! audioTrackIsValid(i)"
                      display-error
                    >
                      <FormSelect
                        :id="`subtitle-language-${i}`"
                        :model-value="getAudioByCode(iso_code)"
                        :most-used-options="frequentlyUsedLanguages"
                        :options="allLanguages"
                        @update:model-value="ingestEnvironmentProcessing.mediainfo.audio[i] = $event.iso_639_3"
                        :with-search="true"
                        :disabled="! ['otto', 'amazon'].includes(ingestEnvironmentProcessing.group_environment)"
                      />
                    </FormControl>
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
        </div>
        <div
          v-if="ingestEnvironmentProcessing.group_environment === 'betv'"
          class="mt-4"
        >
          <FormControl
            v-if="! ingestContainsFrenchTrack"
            :label="$t('videos.modal.processing.betv_subtitles.label')"
            :label-tooltip="{ message: $t('videos.modal.processing.betv_subtitles.tooltip') }"
            class="mt-4"
            :error="! ingestEnvironmentProcessing.validBeTVSubtitlePath && ! ingestEnvironmentProcessing.validating ? $t('videos.subtitles.upload.file_not_found') : ''"
          >
            <FormInput
              v-model="ingestEnvironmentProcessing.be_tv_subtitle"
              autofocus
              :loading="ingestEnvironmentProcessing.validating"
              :valid="environmentIngestIsValid"
              @keyup="updateBeTvSubtitlePath($event)"
              @update:model-value="updateBeTvSubtitlePath($event)"
            />
          </FormControl>
          <FormControl
            class="mt-4"
            :label="$t('videos.modal.processing.public_rating.label')"
          >
            <FormSelect
              class="mt-2 w-40"
              :options="availablePublicRatings"
              :model-value="selectedPublicRating"
              @update:model-value="ingestEnvironmentProcessing.be_tv_classification = $event.id"
            />
          </FormControl>
        </div>
      </div>
    </template>
    <template #footer>
      <BaseButton
        v-if="showFormAddPath || showFormSetIngest"
        :label="$t('button.cancel')"
        outline
        type="primary"
        class="modal-btn"
        @click="cancelIngest"
        :disabled="loading"
        :loading="loading"
      />
      <BaseButton
        v-if="showFormSetIngestAudio"
        :label="$t('button.cancel')"
        outline
        type="primary"
        class="modal-btn"
        @click="cancelIngestAudio"
        :disabled="loading"
        :loading="loading"
      />

      <BaseButton
        v-if="showFormAddPath"
        type="primary"
        :label="$t('button.validate')"
        class="modal-btn"
        :disabled="! allVideoPathsAreValid"
        @click="submitAllPendingPaths"
        :loading="loading"
      />
      <BaseButton
        v-if="showFormSetIngest"
        type="primary"
        :label="$t('button.validate')"
        class="modal-btn"
        :disabled="loading || ! pendingIngestIsValid"
        @click="submitPendingIngest"
        :loading="loading"
      />
      <BaseButton
        v-if="showFormSetIngestAudio"
        type="primary"
        :label="$t('button.confirm')"
        class="modal-btn"
        :disabled="loading || ! environmentIngestIsValid || ! ingestEnvironmentProcessing.processable || ingestEnvironmentProcessing.validating || ! environmentIngestIsValid"
        @click="submitIngestAudio"
        :loading="loading"
      />
    </template>
  </Modal>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { partners }  from '@/data/partners';
import videosTypes from '@/data/videosTypes';
import BaseButton from '@/components/button/BaseButton.vue';
import Card from '@/components/card/Card.vue';
import CardSection from '@/components/card/CardSection.vue';
import FormCheckbox from '@/components/form/FormCheckbox.vue';
import FormControl from '@/components/form/FormControl.vue';
import FormInput from '@/components/form/FormInput.vue';
import FormLabel from '@/components/form/FormLabel.vue';
import FormRadios from '@/components/form/FormRadios.vue';
import FormSelect from '@/components/form/FormSelect.vue';
import Icon from '@/components/icon/Icon.vue';
import IngestingVideo from '@/components/video/IngestingVideo.vue';
import Modal from '@/components/modal/Modal.vue';
import PictoButton from '@/components/button/PictoButton.vue';

export default {
  name: 'ModalVideosUpload',
  components: {
    BaseButton,
    Card,
    CardSection,
    FormCheckbox,
    FormControl,
    FormInput,
    FormLabel,
    FormRadios,
    FormSelect,
    Icon,
    IngestingVideo,
    Modal,
    PictoButton,
  },
  data() {
    return {
      previousIngestsSectionsDefault: ['encoding', 'finished', 'stopped'],
      ingestEnvironmentGroup: [
        {
          id: 'all',
          title: 'videos.modal.import.options.platforms.all',
          label: 'videos.modal.import.options.platforms.all',
        },
        {
          id: 'select',
          title: 'videos.modal.import.options.platforms.select',
          label: 'videos.modal.import.options.platforms.select',
        },
      ],
      runningngestsSections: [
        {
          itemKey: 'encoding',
          title: this.$t('videos.modal.processing.encoding.title'),
          spinner: true,
          removable: false,
          listName: 'activeIngests'
        },
        {
          itemKey: 'finished',
          title: this.$t('videos.modal.processing.encoding.completed'),
          spinner: false,
          removable: true,
          listName: 'finishedIngests'
        },
        {
          itemKey: 'stopped',
          title: this.$t('videos.modal.processing.encoding.error'),
          spinner: false,
          removable: true,
          listName: 'stoppedIngests'
        }
      ],
      availablePublicRatings: [
        {
          id: 'tp',
          title: this.$t('videos.modal.processing.public_rating.values.mtp')
        },
        {
          id: '10',
          title: this.$t('videos.modal.processing.public_rating.values.m10')
        },
        {
          id: '12',
          title: this.$t('videos.modal.processing.public_rating.values.m12')
        },
        {
          id: '16',
          title: this.$t('videos.modal.processing.public_rating.values.m16')
        },
        {
          id: '18',
          title: this.$t('videos.modal.processing.public_rating.values.m18')
        },
      ],

      indexPendingIngest: 0,
      pendingIngest: {path: 'path',  origin_server: 'vodf', valid: true, type: 'main', encoder_queue: 'vodf', environments: []},

      indexBachIngestEnvironmentProcessing: 0,
      indexIngestEnvironmentProcessing: 0,
      ingestEnvironmentProcessing: {path: 'path',  origin_server: 'vodf', valid: true, type: 'main', encoder_queue: 'vodf'},
    }
  },
  computed: {
    activePartners() {
      const temp = [
        {
          id: 'otto',
          title: 'section.videos.partner.otto.title',
          img: {
            url: null
          },
          video: {
            availableFormats: [
              'main',
              'bonus',
              'trailer'
            ]
          }
        },
        ...this.getActivePartners
      ];

      return temp.filter((i) => 
        i?.video?.availableFormats?.includes(this.pendingIngest.type)
      );
    },
    showProcessingIngests() {
      return this.ingestInProgress.length > 0 && this.pendingPaths.length === 0 && this.pendingIngests.length === 0 && this.toSetIngests.length === 0;
    },
    showFormAddPath() {
      return this.pendingPaths.length > 0 && this.pendingIngests.length === 0 && this.toSetIngests.length === 0;
    },
    showFormSetIngest() {
      return this.pendingPaths.length === 0 && this.pendingIngests.length > 0;
    },
    showFormSetIngestAudio() {
      return this.pendingPaths.length === 0 && this.pendingIngests.length === 0 && this.toSetIngests.length > 0;
    },
    allVideoPathsAreValid() {
      return this.pendingPaths.filter((video) => ! video.valid).length === 0;
    },
    environmentSelected() {
      if (this.pendingIngest.environments.length === 0) {
        return this.$t('videos.modal.processing.platform');
      }

      let selected = this.activePartners
        .filter(i => this.pendingIngest.environments.includes(i.id))
        .map(i => this.$t(i.title))
      ;

      return selected.join(', ');
    },
    modalTitle() {
      if (this.showProcessingIngests) {
        // Admin has running ingest and is not adding more ingests
        return this.$t('videos.modal.processing.title');
      } else if (this.showFormAddPath) {
        // Admin is adding new ingests but has not started to edit them
        return this.$t('videos.modal.import.server.title');
      } else if (this.showFormSetIngest) {
        // Admin is adding new ingests and has started to edit them (generic)
        return this.$t('videos.modal.import.file.title');
      } else if (this.showFormSetIngestAudio) {
        // Admin is adding new ingests and has started to edit them (environment)
        let batch = this.toSetIngests[this.indexBachIngestEnvironmentProcessing] ?? [];
        let ingest = batch[this.indexIngestEnvironmentProcessing];
        let partnerId = typeof ingest === 'undefined' ? null : ingest.group_environment;
        let partner = this.activePartners.find((partner) => partner.id === partnerId) ?? { title: null };
        let environment = partner.title || partnerId ? this.$t(partner.title ?? partnerId) : '';

        return this.$t('videos.modal.import.environments.title', {
          step: this.indexIngestEnvironmentProcessing + 1,
          total: batch.length,
          environment: environment,
        }).toUpperCase();
      }

      return this.$t('videos.modal.import.title');
    },
    partners() {
      return cloneDeep(partners)
    },
    videosTypes() {
      return videosTypes.filter((videoType) =>
        ['main', 'bonus', 'trailer'].includes(videoType.type)
      );
    },
    pendingIngestIsValid() {
      return typeof this.videosTypes.find((vt) => vt.id === this.pendingIngest.type) !== 'undefined' && 
        (this.pendingIngest.environments.length > 0 || this.pendingIngest.mode === 'all')
      ;
    },
    environmentIngestIsValid() {
      let ingest = cloneDeep(this.ingestEnvironmentProcessing);
      let audios = cloneDeep(ingest?.mediainfo?.audio ?? []);
      const validISO3Codes = this.allLanguages.map((i) => i.iso_639_3);

      if (typeof audios === 'object') {
        audios = Object.values(audios);
      }

      if (audios.length === 0) {
        console.error("No audiotrack found", ingest.mediainfo);
        return false;
      }

      if (ingest.group_environment === 'molotov') {
        return true; //no other controls needed
      }

      if (ingest.group_environment === 'betv') {
        if (! this.ingestContainsFrenchTrack && ((ingest.be_tv_subtitle ?? '').trim() === '' || ! ingest.validBeTVSubtitlePath)) {
          return false;
        }

        return true; //no other controls needed
      }

      let audiosWithoutSkip = audios.filter((i) => i !== 'xxx');
      let audiosInvalidTracks = audiosWithoutSkip.filter((i) => ! validISO3Codes.includes(i));
      let audiosWithoutSkipAndWithoutDuplicates = [...new Set(audiosWithoutSkip)];

      return audiosWithoutSkip.length> 0 && audiosInvalidTracks.length === 0 && audiosWithoutSkip.length === audiosWithoutSkipAndWithoutDuplicates.length;
    },
    selectedPublicRating() {
      return this.availablePublicRatings.find((i) => i.id === this.ingestEnvironmentProcessing.be_tv_classification) ?? { id: 'tp', title: this.$t('videos.modal.processing.public_rating.values.mtp') };
    },
    ingestContainsFrenchTrack() {
      let copy = cloneDeep(this.ingestEnvironmentProcessing?.mediainfo?.audio ?? []);

      if (typeof copy === 'object') {
        copy = Object.values(copy);
      }

      return ['fr', 'fra', 'fre'].some((i) => copy.includes(i));
    },
    frequentlyUsedLanguages() {
      return this.ingestEnvironmentProcessing.group_environment === 'amazon' ? this.frequentlyUsedLanguagesAmazon : this.frequentlyUsedLanguagesOtto;
    },
    allLanguages() {
      if (this.ingestEnvironmentProcessing.group_environment === 'amazon') {
        return this.allLanguagesAmazon;
      }

      return [
        {
          id: 0,
          title: this.$t('videos.modal.processing.audiosubs.skip'),
          iso_639_3: 'xxx',
        },
        ...this.allLanguagesOtto,
      ]
    },

    ...mapGetters({
      allLanguagesOtto: 'languages/allLanguages',
      allLanguagesAmazon: 'languages/amazonAllLanguages',
      frequentlyUsedLanguagesOtto: 'languages/frequentlyUsedTrue',
      frequentlyUsedLanguagesAmazon: 'languages/amazonFrequentlyUsedTrue',
      getActivePartners: 'platforms/getActivePartnerEnabled',
      activeIngests: 'ingests/activeIngests',
      finishedIngests: 'ingests/finishedIngests',
      stoppedIngests: 'ingests/stoppedIngests',
    }),
    ...mapState({
      loading: (state) => state.ingests.loading,
      ingestInProgress: (state) => state.ingests.all,
      pendingPaths: (state) => state.ingests.pendingPaths,
      pendingIngests: (state) => state.ingests.pendingIngests,
      toSetIngests: (state) => state.ingests.toSetIngests,
    }),
  },
  methods: {
    getPreviousIngestByStatus(listName) {
      return this[listName];
    },
    getAudioByCode(iso_code) {
      return this.allLanguages.find((lang) => lang.iso_639_3 === iso_code) ?? 
        { 
          id: 0,
          title: this.$t('videos.modal.processing.audiosubs.unknown'),
          iso_639_3: iso_code 
        };
    },
    updateEnvironment(id, action) {
      if (action) {
        this.pendingIngest.environments.push(id);
      } else {
        this.pendingIngest.environments = this.pendingIngest.environments.filter(i => i !== id);
      }
    },
    updateEnvironmentByType(type) {
      // Remove environments that are not possible with this video format
      this.pendingIngest.type = type;
      const temp = cloneDeep(this.pendingIngest.environments);
      this.pendingIngest.environments = this.activePartners.filter(i => temp.includes(i.id)).map(i => i.id);
    },
    updateEnvironmentByMode(mode) {
      this.pendingIngest.mode = mode;
      this.pendingIngest.environments = [];
    },
    updateFilePath(index, path) {
      if (typeof this.pendingPaths[index] === 'undefined') {
        return;
      }

      this.updatePendingPath({ index: index, field: 'path', value: path });
      this.validateServerPath(index);
    },
    audioTrackIsValid(index) {
      let audioCodes = cloneDeep(this.ingestEnvironmentProcessing?.mediainfo?.audio ?? []);
      let audioCode = audioCodes[index];
  
      if (audioCode === 'xxx') {
        return true;
      }

      let hasDuplicates = Object.values(audioCodes).filter((i) => i === audioCode).length > 1;
      let validAudioTag = this.allLanguages.filter((i) => i.iso_639_3 === audioCode).length > 0;

      return ! hasDuplicates && validAudioTag;
    },
    cancelIngest() {
      this.archiveAllToSetAudio();
      this.reset('pendingPaths');
      this.reset('pendingIngests');
      this.reset('toSetIngests');
      this.reset('toUpdateIngests');
      this.hideModal('ModalVideosUpload');
    },
    cancelIngestAudio() {
      this.archiveIngest(this.ingestEnvironmentProcessing);
      this.indexIngestEnvironmentProcessing++;
    },
    setIngestEnvironmentProcessing() {
      if (
        typeof this.toSetIngests[this.indexBachIngestEnvironmentProcessing] === 'undefined' ||
        typeof this.toSetIngests[this.indexBachIngestEnvironmentProcessing][this.indexIngestEnvironmentProcessing] === 'undefined'
      ) {
        this.ingestEnvironmentProcessing = { path: 'path', origin_server: 'vodf', valid: true, type: 'main', encoder_queue: 'vodf', environment: {}, validating: false, valid: false};
        return;
      }

      this.ingestEnvironmentProcessing = cloneDeep(this.toSetIngests[this.indexBachIngestEnvironmentProcessing][this.indexIngestEnvironmentProcessing]);
      let environment = this.activePartners.find((partner) => partner.id === this.ingestEnvironmentProcessing?.group_environment) ?? {};
      this.ingestEnvironmentProcessing.refAudios = cloneDeep(this.ingestEnvironmentProcessing?.mediainfo?.audio ?? []);
      this.ingestEnvironmentProcessing.environment = environment;
      this.ingestEnvironmentProcessing.validating = false;
      this.ingestEnvironmentProcessing.validBeTVSubtitlePath = true;
      let audios = this.ingestEnvironmentProcessing?.mediainfo?.audio ?? [];

      if (typeof audios === 'object') {
        audios = Object.values(audios);
      }

      this.ingestEnvironmentProcessing.audioCount = audios.length;

      this.ingestEnvironmentProcessing.processable = true;

      if (
        (this.ingestEnvironmentProcessing.group_environment === 'amazon' && audios.length > 1) ||
        audios.length == 0
      ) {
        this.ingestEnvironmentProcessing.processable = false;
      }
    },
    setPendingIngest() {
      if (typeof this.pendingIngests[this.indexPendingIngest] === 'undefined') {
        this.pendingIngest = { path: 'path', origin_server: 'vodf', valid: true, type: 'main', encoder_queue: 'vodf', environments: [] };
        return;
      }
      this.pendingIngest = cloneDeep(this.pendingIngests[this.indexPendingIngest]);
    },
    submitPendingIngest() {
      let temp = cloneDeep(this.pendingIngest);

      if (temp.mode === 'all') {
        temp.environments = this.activePartners.map(i => i.id);
      }

      this.createRelatedIngests(temp).then(() => {
        this.indexPendingIngest++;
      });
    },
    submitIngestAudio() {
      let audios = cloneDeep(this.ingestEnvironmentProcessing?.mediainfo?.audio ?? {});

      if (Array.isArray(audios)) {
        audios = Object.assign({}, audios);
      }

      audios = Object.entries(audios).reduce((newObj, [key, value]) => {
        if (value !== 'xxx') {
          newObj[key] = value;
        }
        return newObj;
      }, {});

      this.ingestEnvironmentProcessing.mediainfo.audio = audios;
      this.addToUpdateIngests(cloneDeep(this.ingestEnvironmentProcessing));
      this.indexIngestEnvironmentProcessing++;
    },

    updateBeTvSubtitlePath: debounce(function (path) {
      if (path.trim() === '') {
        this.ingestEnvironmentProcessing.validBeTVSubtitlePath = true;
        return;
      }

      let extension = path.split('.').pop();
      if (! ['srt', 'stl', 'vtt', 'pac'].includes(extension)) {
        this.ingestEnvironmentProcessing.validBeTVSubtitlePath = false;
        return;
      }

      const subtitle = {
        path: path,
        origin_server: 'vodf',
      };

      this.ingestEnvironmentProcessing.validating = true;

      this.checkVideo(subtitle)
        .then(async (response) => {
          await this.$nextTick();
          this.ingestEnvironmentProcessing.validBeTVSubtitlePath = (response.path !== false);
        })
        .finally(() => {
          this.ingestEnvironmentProcessing.validating = false;
        });

    }, 500),
    validateServerPath: debounce(function (index) {

      let path = this.pendingPaths[index]?.path;

      if (typeof path === 'undefined' || path.trim() === '') {
        this.updatePendingPath({ index: index, field: 'valid', value: false });
        return;
      }

      path = path.trim();

      let extension = this.pendingPaths[index].path.split('.').pop();

      if (! ['mp4', 'mov', 'ts', 'avi', 'mpg', 'mkv'].includes(extension)) {
        this.updatePendingPath({ index: index, field: 'valid', value: false });
        return;
      }

      this.updatePendingPath({ index: index, field: 'validating', value: true });
      this.updatePendingPath({ index: index, field: 'valid', value: false });

      const video = {
        path: this.pendingPaths[index].path,
        origin_server: 'vodf',
      };

      this.checkVideo(video)
        .then(async (response) => {
          await this.$nextTick();
          this.updatePendingPath({ index: index, field: 'valid', value: response.path !== false });
        })
        .finally(() => {
          this.updatePendingPath({ index: index, field: 'validating', value: false });
        });
    }, 500),

    ...mapActions('languages', {
      getLanguages: 'getAll'
    }),
    ...mapActions('videos', {
      checkVideo: 'checkVideo',
    }),
    ...mapActions('ingests', {
      addPathsToIngest: 'addPathsToIngest',
      submitAllPendingPaths: 'setPendingIngests',
      createRelatedIngests: 'batchCreateIngest',
      updateRelatedIngests: 'batchUpdateIngest',
      archiveIngest: 'archive',
      archiveAllToSetAudio: 'archiveAllToSetAudio',
    }),
    ...mapMutations('ingests', {
      reset: 'reset',
      addPendingPath: 'addPendingPath',
      addToUpdateIngests: 'addToUpdateIngests',
      updatePendingPath: 'updatePendingPath',
    }),
    ...mapMutations(['hideModal']),
  },
  watch: {
    indexPendingIngest() {
      if (this.indexPendingIngest === this.pendingIngests.length) {
        this.reset('pendingIngests');
        return;
      }

      this.setPendingIngest();
    },
    showFormSetIngest() {
      this.indexPendingIngest = 0;
      this.setPendingIngest();
    },
    indexBachIngestEnvironmentProcessing(value) {
      if (value === this.toSetIngests.length) {
        this.reset('toSetIngests');
        this.setIngestEnvironmentProcessing();
        this.updateRelatedIngests();
        return;
      }
    },
    indexIngestEnvironmentProcessing(value) {
      if (
        typeof this.toSetIngests[this.indexBachIngestEnvironmentProcessing] === 'undefined' ||
        value === this.toSetIngests[this.indexBachIngestEnvironmentProcessing].length
      ) {
        this.indexBachIngestEnvironmentProcessing++;
        this.indexIngestEnvironmentProcessing = 0;
        return;
      }

      this.setIngestEnvironmentProcessing();
    },
    showFormSetIngestAudio() {
      this.setIngestEnvironmentProcessing();
    },
  },
  created() {
    this.getLanguages();
    let lastLine = this.pendingPaths[this.pendingPaths.length - 1] ?? null;
    if (this.ingestInProgress.length === 0 && (lastLine === null || lastLine.path !== '')) {
      this.addPendingPath();
    }
  },
};
</script>
