<template>
  <li
    class="main-nav-item border-solid border-b border-b-skin-ui flex-1 md:flex-none self-stretch"
    :class="themeClass"
  >
    <router-link
      :to="url"
      class="main-nav-item-link group relative flex justify-items-center justify-start h-full md:h-18 transition-all before:transition-all ease-in-out before:absolute before:delay-75 before:hover:delay-0 before:top-0 before:scale-x-0 before:hover:scale-x-100 before:left-0 before:w-[3px] before:h-full before:opacity-0 before:transition-all before:duration-200 before:ease-in-out hover:bg-skin-ui-hover before:bg-skin-action-accent before:hover:opacity-100 focus-visible:opacity-100"
      active-class="before:delay-0 before:scale-x-100 before:opacity-100"
    >
      <span
        class="w-16 md:w-18 absolute h-full left-1/2 -translate-x-1/2 md:left-auto md:translate-x-0 opacity-20 transform md:opacity-100 md:relative inline-flex md:flex-none md:pr-0 md:flex items-center justify-center text-skin-accent"
      >
        <Icon :icon="icon" :color="color" :size="size" />
      </span>
      <span
        class="text-2xl md:text-base inline-flex flex-1 md:flex md:flex-1 md:pr-0 items-center justify-center md:justify-start uppercase group-checked:opacity-100 peer-checked:opacity-100 group-hover:opacity-100 group-checked:translate-x-0 peer-checked:translate-x-0 group-hover:translate-x-0 transition duration-300 group-checked:delay-100 group-hover:delay-100 peer-checked:delay-100 ease-in-out"
        :class="
          visible ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1'
        "
      >
        {{ title }}
      </span>
    </router-link>
  </li>
</template>

<script>
import { themeClasses } from '@/utils/styles/styles.js';
import Icon from '@/components/icon/Icon.vue';

export default {
  name: 'NavItem',
  components: {
    Icon: Icon,
  },
  props: {
    url: String,
    icon: String,
    size: String,
    title: String,
    color: String,
    visible: Boolean,
  },
  computed: {
    themeClass() {
      return themeClasses[this.color];
    },
  },
};
</script>

<style lang="scss">
//@import 'nav_item.scss';
</style>
