<template>
  <nav
    id="nav"
    class="group fixed top-[60px] md:pointer-events-auto w-full left-0 bottom-0 bg-skin-ui overflow-hidden md:translate-x-0 md:peer-checked:w-[213px] md:peer-disabled:w-[213px] md:hover:w-[213px] hover:shadow-lg hover:shadow-skin-ui md:group-focus-visible:w-[213px] transition-all duration-300 hover:delay-0 ease-in-out z-50 shadow-md shadow-skin-ui z-[1000]"
    :class="{
      'hovered md:w-[213px]': isNavHovered,
      'md:w-[213px] translate-x-0 opacity-100': expanded,
      'opacity-0 pointer-events-none pointer md:opacity-100': !expanded,
      'md:w-18': !isNavHovered && !$isMobile && !expanded,
    }"
  >
    <ul
      class="h-full flex flex-col items-stretch md:items-start main-nav-list border-solid border-t border-t-skin-ui relative pb-[84px]"
    >
      <NavItem
        v-for="navItem in filteredNavItems"
        :key="navItem.index"
        v-can="navItem.permission"
        :title="$t(navItem.title)"
        :icon="$t(navItem.icon)"
        :size="$isMobile ? 'xlg' : 'lg'"
        :url="navItem.url"
        :color="$t(navItem.color)"
        :visible="expanded"
      />
      <li
        v-if="false"
        class="absolute bottom-6 left-1/2 -translate-x-1/2 flex items-center w-full lg:w-auto group-hover:w-full justify-center"
      >
        <Icon
          icon="sea-scape"
          size="xl"
          class="group-hover:transition-all md:scale-0 md:opacity-0 duration-400 group-hover:opacity-100 group-hover:scale-100 group-hover:delay-150 absolute left-8"
        />
        <FormSwitch
          :checked="isDark"
          :label-as-tooltip="{
            position: 'bottom',
            align: 'right',
          }"
          label-true="Mode Clair"
          label-false="Mode Sombre"
          class="flex items-center justify-center flex-1"
          @update:model-value="toggleDark"
        />
        <Icon
          icon="scuba-diver"
          size="xl"
          class="group-hover:transition-all md:scale-0 md:opacity-0 duration-400 group-hover:opacity-100 group-hover:scale-100 group-hover:delay-150 absolute right-8"
        />
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapGetters, mapMutations } from 'vuex';
import FormCheckbox from '@/components/form/FormCheckbox.vue';
import FormSwitch from '@/components/form/FormSwitch.vue';
import Icon from '@/components/icon/Icon.vue';
import NavItem from '@/components/nav/NavItem.vue';
import navItems from '@/data/navItems';

export default {
  name: 'Nav',
  components: {
    FormSwitch,
    FormCheckbox,
    Icon,
    NavItem,
  },
  inject: ['$isMobile'],
  props: {
    expanded: Boolean,
  },
  data() {
    return {
      isNavHovered: false,
      currentEnv: import.meta.env.VITE_ENV,
      navItems: navItems
    };
  },
  computed: {
    ...mapFields({
      currentPlatform: 'platforms.currentPlatform',
    }),
    pricingType() {
      if (this.currentPlatform) {
        return this.currentPlatform.content_config.pricing_type;
      }
      return null;
    },
    filteredNavItems() {
      return this.navItems.filter(
        (item) =>
          item.visible === true &&
          this.can(item.permission) &&
          (!item?.pricing ||
            (this.pricingType && item.pricing.includes(this.pricingType)))
      );
    },
    ...mapGetters({
      can: 'auth/can',
      isDarkMode: 'platforms/isDarkMode',
    }),
  },
  methods: {
    ...mapMutations({
      switchTheme: 'platforms/switchTheme',
    }),
    expandNav() {
      this.isNavHovered = true;
    },
    collapseNav() {
      this.isNavHovered = false;
    },
  },
};
</script>
<script setup>
/*
import { useDark, useToggle } from '@vueuse/core';
import Tooltip from '@/components/tooltip/Tooltip.vue';


const isDark = useDark({
  selector: 'body',
  attribute: 'class',
  valueDark: 'theme-abyss',
  valueLight: 'theme-light',
  onChanged(dark) {
    const css = document.createElement('style');
    const valueDark = 'theme-abyss';
    css.type = 'text/css';
    css.appendChild(
      document.createTextNode(
        `* {
       -webkit-transition: none !important;
       -moz-transition: none !important;
       -o-transition: none !important;
       -ms-transition: none !important;
       transition: none !important;
    }`
      )
    );
    document.head.appendChild(css);
    document.getElementsByTagName('body')[0].classList.toggle(valueDark, dark);
    const _ = window.getComputedStyle(css).opacity;
    document.head.removeChild(css);
  },
});
const toggleDark = useToggle(isDark);

 */
</script>

<style lang="scss">
//@import 'nav.scss';
</style>
