import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import {
  PutObjectCommand,
  S3Client,
  UploadPartCommand,
} from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { handleResponse } from '@/services/index.js';

const REGION = import.meta.env.VITE_AWS_S3_BUCKET_REGION;
const IDENTITY_POOL_ID = import.meta.env.VITE_AWS_S3_IDENTITY_POOL_ID;

class S3Uploader {
  constructor() {
    this.s3 = new S3Client({
      region: REGION,

      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: REGION }),
        identityPoolId: `${REGION}:${IDENTITY_POOL_ID}`,
      }),
    });
  }

  async uploadFile(payload) {
    let s3 = this.s3;

    let fileName = this.generateName(payload.extension, payload.platformId);
    let options = {
      partSize: 26 * 1024 * 1024, // +-25MB
    };
    let params = {
      Body: payload.file,
      Bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME,
      Key: fileName,
      ACL: 'private',
      ContentType: payload.mimeType,
    };
    const command = new PutObjectCommand(params);
    try {
      const response = await s3.send(command, options);
      const res = {
        status: 'success',
        results: { key: fileName },
      };
      return handleResponse(res);
    } catch (err) {
      return handleResponse(err);
    }
  }

  generateName(extension, platformId) {
    const chars = '0123456789abcdef';
    let text = String(platformId).padStart(6, '0') + '-' + Date.now() + '-';
    for (let i = 0; i < 128; i++) {
      text += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return text + '.' + extension;
  }
}

export default S3Uploader;
