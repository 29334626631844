import { $vfm } from 'vue-final-modal';
import { createStore } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';

/**
 * AWS module
 */
import { s3Uploader } from './aws/s3uploader.module';

/**
 * Content module
 */
import { advertisingBlocks } from './content/advertisingBlocks.module';
import { animation } from './content/animation.module';
import { badges } from './content/badges.module';
import { blocks } from './content/blocks.module';
import { categories } from './content/categories.module';
import { countries } from './content/countries.module';
import { customs } from './content/customs.module';
import { episodes } from './content/episodes.module';
import { gears } from './content/gears.module';
import { groupPlansContent } from './content/groupPlansContent.module';
import { languages } from './content/languages.module';
import { levels } from './content/levels.module';
import { metadataDetails } from './content/metadataDetails.module';
import { metadatas } from './content/metadatas.module';
import { persons } from './content/persons.module';
import { playlists } from './content/playlists.module';
import { programMetadatas } from './content/programMetadatas.module';
import { programs } from './content/programs.module';
import { ratings } from './content/ratings.module';
import { recommendations } from './content/recommendations.module';
import { references } from './content/references.module';
import { rentals } from './content/rentals.module';
import { seasons } from './content/seasons.module';
import { selections } from './content/selections.module';
import { tags } from './content/tags.module';
import { templates } from './content/templates.module';
import { versions } from './content/versions.module';

/**
 * Media module
 */
import { files } from './media/files.module';
import { ingests } from './media/ingests.module';
import { pictures } from './media/pictures.module';
import { playbackConfigurations } from './media/playback-configurations.module';
import { subtitles } from './media/subtitles.module';
import { videos } from './media/videos/videos.module';

/**
 * Platform module
 */
import { emails } from './platform/emails.module';
import { fontFamilies } from './platform/fontFamilies.module';
import { fonts } from './platform/fonts.module';
import { legals } from './platform/legals.module';

/**
 * Billing module
 */
import { coupons } from './billing/coupons.module';
import { currencies } from './billing/currencies.module';
import { customers } from './billing/customers.module';
import { discountCampaigns } from './billing/discountCampaigns.module';
import { groupPlans } from './billing/groupPlans.module';
import { invoices } from './billing/invoices.module';
import { plans } from './billing/plans.module';
import { subscriptions } from './billing/subscriptions.module';
import { transactions } from './billing/transactions.module';

/**
 * User module
 */
import { admins } from './user/admins.module';
import { auth } from './user/auth.module';
import { ftpUsers } from './user/ftpUsers.module';
import { newsletters } from './user/newsletters.module';
import { users } from './user/users.module';

/**
 * Statistics module
 */
import { kpis } from './statistics/kpis.module';
import { registeredKpis } from './statistics/registeredKpis.module';
import { registrationsKpis } from './statistics/registrationsKpis.module';
import { subscribedKpis } from './statistics/subscribedKpis.module';
import { subscriptionsKpis } from './statistics/subscriptionsKpis.module';
//import { transactionsKpis } from './statistics/transactionsKpis.module';
import { unsubscribedKpis } from './statistics/unsubscribedKpis.module';
//import { viewsKpis } from './statistics/viewsKpis.module';
import { topViewsAggregateKpis } from './statistics/topViewsAggregateKpis.module';
import { topViewsKpis } from './statistics/topViewsKpis.module';

/**
 * Profile Module
 */
import { frontProfiles } from './frontProfiles.module';
import { personProfiles } from './personProfiles.module';
import { userProfiles } from './userProfiles.module';

/**
 * Parameters Module
 */
import { metadatasParameters } from './parameters/metadatas.module';
import { siteParameters } from './parameters/site.module';

/**
 * Filters
 */
import { animationFilter } from './filter/animationFilter.module';
import { metadataFilter } from './filter/metadataFilter.module';
import { personFilter } from './filter/personFilter.module';
import { planFilter } from './filter/planFilter.module';
import { programFilter } from './filter/programFilter.module';
import { searchFilterAndSort } from './filter/searchFilterAndSort.module';
import { selectionsFilter } from './filter/selectionsFilter.module';
import { transactionFilter } from './filter/transactionFilter.module';
import { userFilter } from './filter/userFilter.module';
import { videoFilter } from './filter/videoFilter.module';

/**
 * Platform module
 */
import { defineAsyncComponent } from 'vue';
import { picturesConfigurations } from './platform/pictures-configurations.module';
import { platforms } from './platform/platforms.module';

export const mutations = {
  showModal(
    state,
    {
      componentName,
      componentParams,
      size,
      sticky,
      closeOnClickOutside = true,
      showCloseButton = true,
      overflow = 'auto',
      close,
    }
  ) {
    $vfm.show({
      component: defineAsyncComponent(() =>
        import(`../components/modal/${componentName}.vue`)
      ),
      bind: {
        name: componentName,
        params: {
          size,
          sticky,
          closeOnClickOutside,
          showCloseButton,
          overflow,
          close,
          ...componentParams,
        },
      },
    });
  },
  hideModal(state, modalName) {
    $vfm.hide(modalName);
  },
  setModalSize(state, size) {
    state.modalSize = size;
  },
  showModalVideosUpload() {
    $vfm.show({
      component: defineAsyncComponent(() =>
        import(/* @vite-ignore */ `../components/modal/ModalVideosUpload.vue`)
      ),
      bind: {
        name: 'ModalVideosUpload',
        params: {
          size: 'md',
          overflow: 'visible',
          closeOnClickOutside: true,
          showCloseButton: true,
        },
      },
    });
  },
  hideModalVideosUpload(state) {
    $vfm.hideAll();
  },

  showAlert(state, alert) {
    state.alertsVisible = true;
    if (alert) {
      state.alerts.push(alert);
    }
  },
  hideAlert(state, alert) {
    const alertIndex = state.alerts.findIndex(
      (element) => element.id === alert.id
    );
    state.alerts.splice(alertIndex, 1);
    if (state.alerts.length === 0) {
      state.alertsVisible = false;
    }
  },
  updateField,
  loaderVisible(state, value) {
    state.loaderVisible = value;
  },
  updateCurrentNewMovieProgrammationDates(state, programmationDates) {
    state.programs.currentNewMovie.programmationDates = programmationDates;
  },
  updateCurrentNewSelectionProgrammationDates(state, programmationDates) {
    state.selections.currentNewSelection.programmationDates =
      programmationDates;
  },
  updateFilters(state, selectedFilters) {
    state.selectedFilters = selectedFilters;
  },
  addVideo(state, video) {
    state.videos.all.data.push(video);
  },
  addFile(state, file) {
    state.files.all.push(file);
  },
  updateVideo(state, video) {
    const videoIndex = state.videos.all.data.findIndex(
      (x) => x.id === video.id
    );
    state.videos.all.data.splice(videoIndex, 1);
    state.videos.all.data.splice(videoIndex, 0, video);
  },
  removeUploadingVideo(state, video) {
    const videoIndex = state.videos.all.data.findIndex(
      (x) => x.id === video.id
    );
    state.videos.all.data.splice(videoIndex, 1);
  },
  removeIngestedVideo(state, video) {
    const videoIndex = state.videos.all.data.findIndex(
      (x) => x.id === video.id
    );
    state.videos.all.data.splice(videoIndex, 1);
  },
  setAlertTimeout(state, timeout) {
    state.alertTimeout = timeout;
  },
  setRoute(state, route) {
    state.route = route;
  },
  updateFields(state, { field, value }) {
    state[field] = value;
  }
};
export const state = {
  modalVisible: false,
  modalVideosUploadVisible: false,
  modalComponent: null,
  modalSize: 'md',
  modalComponentParams: null,
  modalSticky: false,
  modalCloseOnClickOutside: true,
  modalShowCloseButton: true,
  loaderVisible: false,
  pendingVisible: false,
  //Alerts
  alertsVisible: false,
  alerts: [],
  alertTimeout: null,
  route: null,
};

export const getters = {
  // activeMovies: state => {
  //   return state.programs.all.filter(movie => {
  //     return ['online', 'scheduled'].includes(movie.status)
  //   })
  // },
  //activeSelections: state => {
  //  return state.selections.all.filter(selection => {
  //    return ['online', 'scheduled'].includes(selection.status)
  //  })
  //},
  uploadingVideos(state) {
    if (state.videos.all.data.length > 0) {
      return state.videos.all.data.filter((x) => x.upload && !x.uploaded);
    }
    return [];
  },
  uploadedVideos(state) {
    if (state.videos.all.data.length > 0) {
      return state.videos.all.data.filter((x) => x.upload && x.uploaded);
    }
    return [];
  },
  ingestingVideos(state) {
    if (state.videos.all.data.length > 0) {
      return state.videos.all.data.filter(
        (x) => x.upload && x.uploaded && !x.ingested
      );
    }
    /*
    if (state.ingests.all.data.length > 0) {
      return state.ingests.all.data.filter(x => x.upload && x.uploaded && !x.ingested)
    }
     */
    return [];
  },
  ingestedVideos(state) {
    if (state.videos.all.data.length > 0) {
      return state.videos.all.data.filter(
        (x) => x.upload && x.uploaded && x.ingested
      );
    }
    return [];
  },
  uploadingVideosProgress(state, getters) {
    return getters.uploadingVideos.reduce(
      (sum, video) =>
        sum + video.upload.progress / getters.uploadingVideos.length,
      0
    );
  },
  uploadingVideosTimeRemaining(state, getters) {
    return getters.uploadingVideos.reduce(
      (sum, video) => sum + video.upload.timeRemaining,
      0
    );
  },

  uploadingFiles(state) {
    if (state.files.all.length > 0) {
      return state.files.all.filter((x) => !x.uploaded);
    }
    return [];
  },
  uploadingFilesProgress(state, getters) {
    return getters.uploadingFiles.reduce(
      (sum, file) => sum + file.upload.progress / getters.uploadingFiles.length,
      0
    );
  },
  uploadingFilesTimeRemaining(state, getters) {
    return getters.uploadingFiles.reduce(
      (sum, file) => sum + file.upload.timeRemaining,
      0
    );
  },

  getField,
};

export const actions = {
  displayAlert({ state, commit }, alert) {
    clearTimeout(state.alertTimeout);
    commit('hideAlert', alert);
    commit('showAlert', alert);
    const timeout = setTimeout(() => {
      commit('hideAlert', alert);
    }, 5000);
    commit('setAlertTimeout', timeout);
  },
  displayLoader({ state, commit }, value) {
    commit('loaderVisible', value);
  },
  addVideoAction({ commit }, video) {
    commit('addVideo', video);
  },
  updateVideoAction({ commit }, video) {
    commit('updateVideo', video);
  },
  removeIngestedVideoAction({ commit, dispatch }, video) {
    commit('removeIngestedVideo', video);
  },
  removeUploadingVideoAction({ commit, dispatch }, video) {
    commit('removeUploadingVideo', video);
    const alert = {
      id: 'video-removed',
      icon: 'trash',
      type: 'valid',
      message: `La vidéo ${video.title} a bien été supprimée`,
    };
    dispatch('displayAlert', alert);
  },
};

const store = createStore({
  mutations,
  state,
  getters,
  actions,
  modules: {
    admins,
    advertisingBlocks,
    animation,
    animationFilter,
    auth,
    badges,
    blocks,
    categories,
    countries,
    coupons,
    currencies,
    customers,
    customs,
    discountCampaigns,
    emails,
    episodes,
    files,
    fontFamilies,
    fonts,
    frontProfiles,
    ftpUsers,
    gears,
    groupPlans,
    groupPlansContent,
    ingests,
    invoices,
    kpis,
    languages,
    legals,
    levels,
    metadataDetails,
    metadataFilter,
    metadatas,
    metadatasParameters,
    newsletters,
    personFilter,
    planFilter,
    personProfiles,
    persons,
    pictures,
    picturesConfigurations,
    plans,
    platforms,
    playbackConfigurations,
    playlists,
    programFilter,
    programMetadatas,
    programs,
    ratings,
    recommendations,
    references,
    registeredKpis,
    registrationsKpis,
    rentals,
    s3Uploader,
    searchFilterAndSort,
    seasons,
    selections,
    selectionsFilter,
    siteParameters,
    subscribedKpis,
    subscriptions,
    subscriptionsKpis,
    subtitles,
    tags,
    templates,
    transactionFilter,
    transactions,
    //transactionsKpis,
    unsubscribedKpis,
    userFilter,
    userProfiles,
    users,
    versions,
    videoFilter,
    videos,
    topViewsKpis,
    topViewsAggregateKpis,
  },
});

export default store;
