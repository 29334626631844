import facets from '@/data/facets.js';
import sorts from '@/data/sorts.js';
import store from "@/store";
import { cloneDeep } from 'lodash';


const state = {
  sorts: [],
  facets: [],
};

const actions = {
  initProgramsGenericFacets({ commit, rootState }) {
    const live =
      rootState.platforms.currentPlatform.content_config.feature.live === true;
    const collection =
      rootState.platforms.currentPlatform.content_config.feature.collection ===
      true;
    const optionalFormats = {
      live,
      collection,
    };
    commit('initProgramsGenericFacets', optionalFormats);
    return Promise.resolve();
  },
  initProgramsCategoriesFacets({ commit, rootState }, categories) {
    commit('initProgramsCategoriesFacets', categories);
    return Promise.resolve();
  },
  initProgramsSort({ commit }, categories) {
    commit('initProgramsSort', categories);
  },
  resetProgramFacets({ commit }) {
    commit('resetProgramFacets');
  },
};

const mutations = {
  initProgramsGenericFacets(state, optionalFormats) {
    state.facets = cloneDeep(facets);
    // Remove live from format filters if the feature is not activated
    if (!optionalFormats.live) {
      let formats = state.facets.find((facet) => facet.id === 'formats');
      const liveFormatIndex = formats.filters.findIndex(
        (format) => format.id === 5
      );
      formats.filters.splice(liveFormatIndex, 1);
    }
    //Remove collection from format filters if the feature is not activated
    if (!optionalFormats.collection) {
      let formats = state.facets.find((facet) => facet.id === 'formats');
      const collectionFormatIndex = formats.filters.findIndex(
        (format) => format.id === 6
      );
      formats.filters.splice(collectionFormatIndex, 1);
    }
  },
  initProgramsCategoriesFacets(state, categories) {
    state.facets = cloneDeep(facets);
    let status = state.facets.find((facet) => facet.id === 'status');
    const offlineStatusIndex = status.filters.findIndex(
      (format) => format.id === 'offline'
    );
    status.filters.splice(offlineStatusIndex, 1);

    const categoriesFacets = {
      title: 'search.categories',
      id: 'categories',
      checked: false,
      cols: 'auto',
      filters: categories.map((category) =>
        Object.assign({}, category, { facet_id: 'categories', checked: false })
      ),
    };
    if (!state.facets[2]) {
      state.facets.push(categoriesFacets);
    }
  },
  initProgramsSort(state) {
    state.sorts = [
      {
        id: 'date:desc',
        title: 'search.added',
      },
      {
        id: 'title:asc',
        title: 'A - Z',
      },
      {
        id: 'title:desc',
        title: 'Z - A',
      },
    ];

    if (store.getters['auth/can']('animation.beta')) {
      state.sorts.splice(1, 0, {
        id: 'generated_at:desc',
        title: 'search.indexed',
      });
    }
  },
  resetProgramFacets(state) {
    state.facets.forEach((facet) => {
      facet.filters.forEach((filter) => {
        filter.checked = false;
      });
    });
  },
};
export const programFilter = {
  namespaced: true,
  state,
  actions,
  mutations,
};
