import sorts from '@/data/sorts.js';
const state = {
  sorts,
  facets: []
};

const actions = {
  initVideosSort({ commit }) {
    commit('initVideosSort');
  },
  initVideosFacets({ commit, rootGetters }, videoTypes) {
    const videoTypesFacets = {
      title: 'videos.search.type',
      id: 'type',
      checked: false,
      cols: 'auto',
      filters: videoTypes.map((videoType) =>
        Object.assign({}, videoType, { facet_id: 'type', checked: false })
      ),
    };
    let facets = [videoTypesFacets];

    const groupEnvironments = rootGetters['platforms/getActivePartnerEnabled'];
    const hasFreeboxPlatform = rootGetters['platforms/hasFreeboxPlatform'];
    if (groupEnvironments.length > 0 || hasFreeboxPlatform) {
      groupEnvironments.unshift({id: "otto", title: "Otto", icon: "otto"});
      if (hasFreeboxPlatform) {
        groupEnvironments.push({id: 'free', title: 'Free', icon: 'free-simple-logo'});
      }
      const videoGroupEnvironmentFacets = {
        title: 'videos.search.group_environment',
        id: 'group_environment',
        checked: false,
        cols: 'auto',
        filters: groupEnvironments
          .map((groupEnv) =>
            Object.assign({}, groupEnv, {facet_id: 'group_environment', checked: false})
          )
      };
      facets.push(videoGroupEnvironmentFacets);
    }
    commit('initVideosFacets', facets);
  },
};

const mutations = {
  initVideosSort(state) {
    state.sorts = sorts;
  },
  initVideosFacets(state, facets) {
    state.facets = facets;
  },
};
export const videoFilter = {
  namespaced: true,
  state,
  actions,
  mutations,
};
