import { ingestService } from '@/services';
import { cloneDeep } from 'lodash';

const state = {
  signedUrls: [],
  all: [],
  pendingPaths: [], // file path added by user contains,  object such as {path: 'path',  origin_server: 'vodf', valid: false, validating: false}
  pendingIngests: [], // basic ingests with more data, object such as {path: 'path',  origin_server: 'vodf', valid: true, video_type: 'main', encoder_queue: 'vodf'}
  toSetIngests: [],
  toUpdateIngests: [],
  loading: true,
};

const CREATED_STATUS_CODE = 'CRE';
const PENDING_STATUS_CODE = 'PEN';
const ACTIVE_STATUS_CODE = 'ACT';
const ARCHIVED_STATUS_CODE = 'ARC';
const ERROR_STATUS_CODE = 'ERR';

const actions = {
  // VALIDATED
  getAll({ commit, rootState }) {
    commit('setLoading', true);
    let userId = rootState.auth.authUser.id;
    let path = `?user_id=${userId}&status=in:${CREATED_STATUS_CODE},${PENDING_STATUS_CODE},${ACTIVE_STATUS_CODE},${ERROR_STATUS_CODE}`;
    return ingestService.getAll(path).then(
      (data) => {
        commit('setLoading', false);
        commit('getAllSuccess', data);
        return Promise.resolve(data);
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  // VALIDATED
  batchCreateIngest({ commit, rootState }, value) {
    commit('setLoading', true);
    let promises = [];
    const drmOption = rootState.platforms.currentPlatform?.ingest_config?.drm ?? false;
    const drm = value.type === 'main' && drmOption ? 1 : 0;
    let userId = rootState.auth.authUser.id;

    value.environments.forEach((environment) => {
      let encoderQueue = 'vodf';

      if (['free', 'betv', 'molotov', 'amazon'].includes(environment)) {
        encoderQueue = environment;
      }

      promises.push(ingestService.create({
        id: value.id,
        file_name: value.file_name,
        name: value.name,
        encoder_queue: encoderQueue,
        origin_server: value.origin_server,
        input_file: value.input_file,
        user_id: userId,
        drm: drm,
        ingest_type: value.type !== 'trailer' ? 'full' : 'mp4',
        video_type_id: value.type === 'main' ? 1 : value.type === 'bonus' ? 2 : 3,
      }));
    });

    return Promise.all(promises)
      .then((response) => {
        commit('postSuccess', response);
        commit('setLoading', false);
        return Promise.resolve(response);
      })
      .catch((error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      });
  },
  //VALIDATED
  batchUpdateIngest({ commit, dispatch, state }) {
    commit('setLoading', true);
    let promises = [];
    const ingestToLaunch = cloneDeep(state.toUpdateIngests);
    ingestToLaunch.forEach((ingest) => {
      promises.push(ingestService.update(ingest, ingest.id));
    });

    return Promise.all(promises)
      .then((response) => {
        let idsToProcess = response.map((ingest) => {
          return ingest.id;
        })

        ingestService.launch({ ids: idsToProcess }).then((ingestIds) => {
          const alert = {
            id: 'video-ingesting',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });

          commit('appendRunningIngest', ingestToLaunch);
          commit('reset', 'toUpdateIngests');
          commit('reset', 'toSetIngests');
          commit('setLoading', false);
          return Promise.resolve(response);
        }).catch((error) => {
          commit('setLoading', false);
          return Promise.reject(error);
        });
      })
      .catch((error) => {
        commit('reset', 'toUpdateIngests');
        commit('reset', 'toSetIngests');
        commit('setLoading', false);
        return Promise.reject(error);
      });
  },
  // VALIDATED
  archive({ commit }, ingest) {
    let ingestId = ingest.id;
    return ingestService.archive(ingestId).then(
      (data) => {
        commit('archiveSuccess', ingestId);
        return Promise.resolve(data);
      },
      (error) => Promise.reject(error)
    );
  },
  archiveAllToSetAudio({ state }) {
    let promises = [];

    state.toSetIngests.forEach((batch) => {
      batch.forEach((ingest) => {
        promises.push(ingestService.archive(ingest.id));
      });
    });

    return Promise.all(promises)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  // VALIDATED
  setPendingIngests({ commit, rootState }) {
    commit('setPendingIngests', { rootState });
    commit('reset', 'pendingPaths');
  },
};

const mutations = {
  // VALIDATED
  addPendingPath(state) {
    state.pendingPaths.push({ path: null, origin_server: 'vodf', validating: false, valid: false });
  },
  // VALIDATED
  updatePendingPath(state, { index, field, value }) {
    if (typeof state.pendingPaths[index] === 'undefined') {
      return;
    }
    state.pendingPaths[index][field] = value;
  },
  // VALIDATED
  setPendingIngests(state, { rootState }) {
    let userId = rootState.auth.authUser.id;

    state.pendingPaths.forEach((pendingPath) => {
      let uniqueIdentifier = Math.floor(Math.random() * 1000000);
      let fileNameWithExtension = pendingPath.path.split('/').pop();
      let fileName = fileNameWithExtension.replace(/\.[^/.]+$/, '');
      let pathOttoEncoder =
        pendingPath.path.charAt(0) === '/'
          ? pendingPath.path.replace('/', '')
          : pendingPath.path;
      let cleanFileName = fileName + '';
      cleanFileName = cleanFileName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
      cleanFileName = cleanFileName.replaceAll(' ', '_');

      state.pendingIngests.push({
        id: uniqueIdentifier + fileName,
        file_name: fileNameWithExtension,
        name: cleanFileName,
        ready_for_ingest: false,
        environments: [],
        origin_server: pendingPath.origin_server,
        input_file: pathOttoEncoder,
        mode: 'all',
        user_id: userId,
        type: 'main'
      });
    });
  },
  // VALIDATED
  addToUpdateIngests(state, ingest) {
    delete ingest.environment;
    delete ingest.refAudios;
    delete ingest.validating;
    delete ingest.valid;
    if (typeof ingest.be_tv_classification !== 'undefined' && ingest.be_tv_classification === 'tp') {
      ingest.be_tv_classification = null;
    }
    state.toUpdateIngests.push(ingest);
  },
  // VALIDATED
  reset(state, key) {
    state[key] = [];
  },
  appendRunningIngest(state, payload) {
    state.all.push(...payload);
  },
  // VALIDATED
  getAllSuccess(state, payload) {
    state.all = payload;
  },
  // VALIDATED
  getAllRequest(state) {
    if (state.all.length > 0) {
      state.all = [];
    }
  },
  // VALIDATED
  postSuccess(state, payload) {
    payload.forEach((ingest) => {
      ingest.processing = false;
    });

    state.toSetIngests.push(payload);
  },
  // VALIDATED
  archiveSuccess(state, ingestId) {
    let ingestToArchive = state.all.find((ingest) => ingest.id === ingestId);
    if (ingestToArchive) {
      ingestToArchive.status_code = ARCHIVED_STATUS_CODE;
    }
  },
  setLoading(state, status) {
    state.loading = status;
  },
};

const getters = {
  loading: (state) => state.loading,
  activeIngests: (state) =>
    state.all.filter((ingest) =>
      [CREATED_STATUS_CODE, PENDING_STATUS_CODE].includes(ingest.status_code)
    ),
  finishedIngests: (state) =>
    state.all.filter((ingest) => ingest.status_code === ACTIVE_STATUS_CODE),
  stoppedIngests: (state) =>
    state.all.filter((ingest) => ingest.status_code === ERROR_STATUS_CODE),
};

export const ingests = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
